import CommonImages from "@base/assets/images";
import RatingUI from "../Rating";
import TypographyUI from "../Typography";
import TagUI from "../Tag";
import ButtonUI from "../Button";
import { createSearchParams, useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import { IDoctor } from "@base/modules/doctor/interface/doctor.interface";
import ImageUI from "../Image/Index";
import { getAvatarLink, getMinMax } from "@base/helpers/common";
import useToggle from "@base/hooks/useToggle";
import ModalConfirmationUI from "../ModalConfirmation";
import CommonIcons from "@base/assets/icons";
import { responseUI } from "@base/themes/response";
import { ECurrency } from "@base/enums";

interface IProps {
  doctor?: IDoctor;
  root?: "specialist" | "csyt";
  handleDetail?: () => void;
}
export default function CardDoctorSpecial(props: IProps) {
  //! state
  const { doctor, root, handleDetail } = props;
  const navigate = useNavigate();
  const specialist = root === "specialist";
  const price = getMinMax(Number(doctor?.price), Number(doctor?.price_online));
  const { open, shouldRender, toggle } = useToggle();

  //! function
  const handleDetailDoctor = () => {
    navigate(`${baseRoutes.doctor}/${doctor?.doctor_code}`);
  };

  const handleBookingDoctor = () => {
    let typeParams: null | string = null;
    if (doctor?.offline_dr && doctor.online_dr) {
      toggle();
      return;
    }
    if (doctor?.offline_dr) {
      typeParams = "offline";
    }
    if (doctor?.online_dr) {
      typeParams = "online";
    }
    navigate({
      pathname: baseRoutes.bookingAppointment,
      search: createSearchParams({
        doctor_code: String(doctor?.doctor_code),
        site_code: String(doctor?.site_code),
        specialization_code: String(doctor?.specialization?.specialization_code),
        type: String(typeParams),
      }).toString(),
    });
  };

  //! render
  return (
    <div className="flex gap-3 border rounded-xl  p-3 lg:p-4">
      <div className="w-[112px] flex items-start">
        <div className="relative w-[112px] lg:w-[212px]">
          <img
            className="rounded-lg aspect-square"
            src={getAvatarLink(doctor?.avatar ?? [])}
            alt={getAvatarLink(doctor?.avatar ?? [])}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = CommonImages.DefaultDoctorAvatarSquare;
            }}
          />
          <RatingUI className="bg-common-white absolute top-0 right-0" maxRating={5} rating={doctor?.rating_point ?? 0} />
        </div>
      </div>

      <div className="grow flex flex-col justify-between gap-2">
        <TypographyUI className="font-semibold lg:text-base text-common-maintext">{doctor?.doctor_name}</TypographyUI>

        <div className="flex">
          {doctor?.online_dr && <TagUI className="px-2 py-1 bg-common-divider text-common-maintext text-xs lg:text-sm" tagName="Tư vấn online" />}
          {doctor?.offline_dr && <TagUI className="px-2 py-1 bg-common-divider text-common-maintext text-xs lg:text-sm" tagName="Tại CSYT" />}
        </div>

        {!specialist && (
          <TypographyUI className="lg:text-base text-common-subtext">
            Chuyên khoa: <TypographyUI className="text-common-maintext lg:text-base">{doctor?.specialization?.description}</TypographyUI>
          </TypographyUI>
        )}

        {!specialist && (
          <TypographyUI className="lg:text-base text-common-subtext">
            Chức vụ: <TypographyUI className="text-common-maintext lg:text-base">{doctor?.title?.title_name}</TypographyUI>
          </TypographyUI>
        )}

        {specialist && (
          <TypographyUI className="lg:text-base text-common-subtext">
            Nơi công tác: <TypographyUI className="text-common-maintext lg:text-base">{doctor?.site_name}</TypographyUI>
          </TypographyUI>
        )}

        {specialist && (
          <TypographyUI className="lg:text-base text-common-subtext">
            Khu vực: <TypographyUI className="text-common-maintext lg:text-base">{doctor?.state_name}</TypographyUI>
          </TypographyUI>
        )}

        {(price?.min ?? 0) > 0 && (
          <TypographyUI className="lg:text-base text-common-subtext">
            Giá khám:{" "}
            <TypographyUI className="!text-common-inprogress lg:text-base">
              {(price?.min ?? 0).toLocaleString()}
              {ECurrency.VND} {price?.max ?? 0 > 0 ? `- ${price?.max.toLocaleString()}${ECurrency.VND}` : ""}
            </TypographyUI>
          </TypographyUI>
        )}

        <div className="flex flex-col gap-2 md:flex-row md:gap-6 md:items-center">
          <ButtonUI className="lg:h-10 font-medium" onClick={handleBookingDoctor}>
            Đăng ký khám
          </ButtonUI>
          <ButtonUI
            onClick={handleDetail ?? handleDetailDoctor}
            className="lg:h-10 font-medium bg-common-orange100 text-common-mainColor hover:!bg-common-orange200 hover:!text-common-mainColor"
          >
            Xem chi tiết
          </ButtonUI>
        </div>
      </div>

      {shouldRender && (
        <ModalConfirmationUI
          type="normal"
          title="Chọn hình thức đặt lịch"
          open={open}
          toggle={toggle}
          hiddenAction
          content={<MethodBooking doctor={doctor} />}
        />
      )}
    </div>
  );
}

const MethodBooking = ({ doctor }: { doctor?: IDoctor }) => {
  const navigate = useNavigate();
  const handleBooking = (type: "online" | "offline") => {
    navigate({
      pathname: baseRoutes.bookingAppointment,
      search: createSearchParams({
        doctor_code: String(doctor?.doctor_code),
        site_code: String(doctor?.site_code),
        specialization_code: String(doctor?.specialization?.specialization_code),
        type: String(type),
      }).toString(),
    });
  };
  return (
    <div>
      <div className="flex flex-col gap-3">
        <ButtonUI
          icon={<CommonIcons.RightOutlined />}
          iconPosition="end"
          type="default"
          className={`${responseUI.button.default} font-medium bg-common-orange100 !py-8  min-w-64 justify-between`}
          onClick={() => {
            handleBooking("online");
          }}
        >
          <div className="flex gap-2 items-center">
            <CommonIcons.IconCameraBox />
            <TypographyUI className="text-base font-medium">Tư vấn online</TypographyUI>
          </div>
        </ButtonUI>

        <ButtonUI
          icon={<CommonIcons.RightOutlined />}
          iconPosition="end"
          type="default"
          className={`${responseUI.button.default} font-medium !py-8  bg-common-blue100 min-w-64 justify-between`}
          onClick={() => {
            handleBooking("offline");
          }}
        >
          <div className="flex gap-2 items-center ">
            <div className="">
              <CommonIcons.IconFacilityBox />
            </div>
            <TypographyUI className="text-base font-medium">Khám tại cơ sở y tế</TypographyUI>
          </div>
        </ButtonUI>
      </div>
    </div>
  );
};
