export enum BankCode {
  VNPAY = "VNPAYQR",
  ATM = "VNBANK",
  MOMO = "MOMO",
  CASH = "CASH",
  VITAPAY = "VITAPAY",
  BANK_TRANSFER = "",
}

export enum EPromotionUnit {
  percentage = "%",
  VND = "VND",
}

export enum EOrderType {
  APPT = "APPT",
  PACKAGE = "PACKAGE",
}

export enum EStatusCode {
  Success = 200,
  Created = 201,
  InternalServer = 500,
}

export enum EAppointmentStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export enum EAppointmentType {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export enum ESpecialPackageStatus {
  UNPAID = "UNPAID",
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export enum ETagSpecialPackageDetailType {
  ONLINE_SERVICE = "ONLINE_SERVICE",
  OFFLINE_SERVICE = "OFFLINE_SERVICE",
  HOMECARE_SERVICE = "HOMECARE_SERVICE",
}

export enum EOrderStatus {
  UNPAID = "UNPAID",
  PAID = "PAID",
  REFUND = "REFUND",
  CANCELLED = "CANCELLED",
}

export enum EPaymentMethod {
  CASH = "CASH",
  TRANSFER = "TRANSFER",
  GATEWAY = "GATEWAY",
}

export enum ETypeServicePackage {
  OFFLINE_SERVICE = "OFFLINE_SERVICE",
  ONLINE_SERVICE = "ONLINE_SERVICE",
}

export enum EDrugOrderStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export enum ECurrency {
  VND = " VNĐ",
}

export enum EUserType {
  PATIENT = "PATIENT",
}

export enum ECollaborationType {
  SITE = "SITE",
  PERSONAL = "PERSONAL",
}
