import React from "react";
import CommonIcons from "@base/assets/icons";
import CommonStyles from "..";
import themeColor from "@base/themes/colorTheme";

export default function CardTransferInfo({ showDetail }: { showDetail?: () => void }) {
  return (
    <div className="mt-3 p-3 border border-common-blue500 bg-common-blue100 rounded-lg">
      <div className="flex items-start gap-2">
        <div>
          <CommonIcons.InfoCircleOutlined style={{ color: themeColor.blue500, fontSize: 18 }} />
        </div>

        <div>
          <CommonStyles.TypographyUI use="paragraph" className="text-sm font-normal text-common-blue500 !mb-0">
            Nếu bạn đã thanh toán bằng hình thức chuyển khoản hoặc tiền mặt, hệ thống sẽ cập nhật và phản hồi trong thời gian sớm nhất. Xin cảm ơn!
          </CommonStyles.TypographyUI>
          <CommonStyles.TypographyUI use="paragraph" className="text-sm font-medium text-common-blue500 !mb-0 cursor-pointer" onClick={showDetail}>
            Xem chi tiết &gt;
          </CommonStyles.TypographyUI>
        </div>
      </div>
    </div>
  );
}
