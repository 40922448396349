import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import useWindowDimensions from "@base/hooks/useWindowDimensions";
import useGetSiteSpecialPackage from "@base/modules/siteSpecialPackage/hooks/useGetSiteSpecialPackage";
import { ISiteSpecialPackage } from "@base/modules/siteSpecialPackage/interface/ISiteSpecialPackage";
import IFilterSiteSpecialPackage from "@base/modules/siteSpecialPackage/interface/filterSiteSpecialPackage";
import { ISpecialCategory } from "@base/modules/specialCategory/hooks/interface/ISpecialCategory";
import CommonStyles from "@components/CommonStyles";
import CardPackageService from "@components/CommonStyles/CardPackageService";
import { Divider } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Slider from "react-slick";

interface IProps {
  category?: ISpecialCategory;
}

export default function AllPackage(props: IProps) {
  //! state
  const { category } = props;
  const { t, i18n } = useTranslation();
  const [parmas] = useSearchParams();
  const packageSearch = parmas.get("package") ?? "";

  let sliderRef = useRef<Slider>(null);
  const settingCarousel = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          className: "center",
          centerMode: true,
          centerPadding: "30px",
        },
      },
    ],
  };

  const footers = [
    { icon: CommonIcons.IconHomeService, title: "home.homeService" },
    { icon: CommonIcons.IconPhone24h, title: "home.phone24h" },
    { icon: CommonIcons.IconCalenderPlus, title: "home.noWait" },
    { icon: CommonIcons.IconLikeStart, title: "home.profesional" },
  ];
  const [filters, setFilters] = useState<IFilterSiteSpecialPackage>({ lang: "", note: String(packageSearch), page_size: 20 });
  const { data: dataPackage, refetch: refetchPackage } = useGetSiteSpecialPackage(filters, false);
  const listPackage = dataPackage?.data.content_page;

  const { width } = useWindowDimensions();

  const [mode, setMode] = useState<"top" | "right" | "bottom" | "left">("left");

  useEffect(() => {
    if (width <= 500) {
      setMode("top");
    } else {
      setMode("left");
    }
  }, [width]);
  //! function
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const handlePrevious = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    if (packageSearch === category?.name && !isEmpty(filters.lang)) {
      refetchPackage();
    }
  }, [packageSearch, filters]);

  useEffect(() => {
    setFilters((prev) => ({ ...prev, lang: i18n.resolvedLanguage }));
  }, [i18n.resolvedLanguage]);
  //! render
  const renderFooter = () => {
    return footers.map((item, index) => {
      const border = footers.length - 1 !== index ? "border-r" : "";
      return (
        <div className={`flex flex-wrap lg:flex-nowrap items-center gap-2 ${border}`} key={index}>
          <item.icon />
          <CommonStyles.TypographyUI className="text-common-maintext  !text-xs xl:!text-sm">{t(item.title)}</CommonStyles.TypographyUI>
        </div>
      );
    });
  };

  const renderPackage = () => {
    return listPackage?.map((item, index) => {
      return (
        <div key={index} className="px-3 h-full">
          <CardPackageService data={item} root="specialist" />
        </div>
      );
    });
  };

  return (
    <div className="flex flex-col justify-between gap-3 h-full">
      <div className="grow">
        <CommonStyles.CarouselUI refSlider={sliderRef} settings={settingCarousel}>
          {renderPackage()}
        </CommonStyles.CarouselUI>
      </div>
      {!!listPackage && listPackage?.length > 0 && (
        <div className=" flex flex-row justify-center lg:justify-between items-center">
          <div className="hidden lg:grid grid-cols-2 lg:grid-cols-4 bg-white px-6 py-2 rounded-2xl gap-2 xl:gap-6 mx-4">{renderFooter()}</div>
          <div className="hidden sm:flex gap-4">
            <CommonStyles.ButtonUI onClick={handlePrevious} shape="circle" icon={<CommonIcons.LeftOutlined />} />
            <CommonStyles.ButtonUI onClick={handleNext} shape="circle" icon={<CommonIcons.RightOutlined />} />
          </div>
        </div>
      )}
    </div>
  );
}
