import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { ISite, ISitePage } from "./site.interface";

class SiteService {
  // get list
  getList(
    page_number: number,
    page_size: number,
    state_id?: string | number | null,
    district_code?: string | null,
    ward_code?: string | null,
    supported_seller?: string | null,
    search_text?: string | number | null,
    only_allow_sign_from_his?: boolean,
  ) {
    return httpService.get<ISitePage>(`${apiService.auth.site.getList}`, {
      params: { page_number, page_size, state_id, district_code, ward_code, supported_seller, search_text, only_allow_sign_from_his },
    });
  }

  // get by code
  getByCode(code: string) {
    return httpService.get<ISite>(`${apiService.auth.site.getByCode}/${code}`);
  }

  // get by id
  getById(id: string | number) {
    return httpService.get<ISite>(`${apiService.auth.site.getById}/${id}`);
  }
}

export default new SiteService();
