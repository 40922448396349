import { getErrorMsg } from "@base/helpers/common";
import { useState } from "react";
import orderService from "../orderService";
import { IPayloadOrder } from "../interface/IPayloadOrder";
import { BankCode, EOrderType, EStatusCode } from "@base/enums";
import { baseRoutes } from "@base/routes/baseRoutes";
import { useDispatch } from "react-redux";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import { useTranslation } from "react-i18next";

const useOrder = (lang = "vi") => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const handleOrder = async (body: IPayloadOrder) => {
    try {
      const resultOrder = await orderService.createOrder(body, lang);
      if (resultOrder.status === EStatusCode.Success || resultOrder.status === EStatusCode.Created) {
        if (body.bank_code && body.bank_code !== BankCode.CASH && resultOrder.data.payment_url) {
          location.replace(resultOrder.data.payment_url);
          return;
        }

        if (resultOrder.data.order_type == EOrderType.APPT && resultOrder.data.ref_key) {
          location.replace(`${baseRoutes.userAppointmentDetail.replace(":key", resultOrder.data.ref_key)}`);
          return;
        }

        if (resultOrder.data.order_type == EOrderType.PACKAGE && resultOrder.data.ref_key) {
          location.replace(`${baseRoutes.userPackageDetail.replace(":key", resultOrder.data.ref_key)}`);
          return;
        }

        location.replace(`${baseRoutes.orderResult}?payment=${EStatusCode.Success}`);
      }
    } catch (error) {
      setLoading(false);
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: t(`${getErrorMsg(error)}`) + "!" }));
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    error,
    handleOrder,
  };
};

export default useOrder;
