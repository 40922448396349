import CommonIcons from "@base/assets/icons";
import { articles } from "@base/constants/mocks";
import MedicalManualLayout from "@base/layouts/MedicalManual/MedicalManualLayout";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonStyles from "@components/CommonStyles";
import CardArticle from "@components/CommonStyles/CardArticle";

export default function MedicalInfo() {
  //! state
  const breadcrumItem = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      title: "Tin tức",
    },
  ];
  //! function
  const renderArticleInfo = () => {
    return articles.map((item, index) => {
      return <CardArticle title={item.title} content={item.content} date={item.date} key={index} />;
    });
  };

  //! render
  const header = () => {
    return (
      <div className="max-w-[800px] flex flex-col h-full justify-between">
        {/* <CommonStyles.BreadcrumbUI className="py-5" items={breadcrumItem} /> */}
        <div>
          <CommonStyles.TypographyUI use="title" className="font-semibold text-common-maintext" level={2}>
            Thông tin y tế
          </CommonStyles.TypographyUI>
          <CommonStyles.TypographyUI use="paragraph" className="text-common-subtext  break-words">
            {`Nắm bắt nhanh chóng các thông tin y tế chính xác, tham khảo trải nghiệm khám chữa bệnh từ các người dùng khác.`}
          </CommonStyles.TypographyUI>
        </div>
      </div>
    );
  };
  return (
    <MedicalManualLayout
      Header={header()}
      Content={
        <div className="grid grid-cols-2 gap-8">
          {renderArticleInfo()}
          <div className="col-span-2 flex justify-center">
            <CommonStyles.PaginationUI pageSize={1} total={50} />
          </div>
        </div>
      }
    />
  );
}
