import CommonIcons from "@base/assets/icons";
import CommonStyles from "@components/CommonStyles";

export default function Comment() {
  //! state
  //! function
  //! render
  return (
    <div className="bg-common-white mt-10 px-10 py-9 rounded-lg">
      <CommonStyles.TypographyUI use="title" className="text-common-maintext font-semibold" level={3}>
        Bình luận (23)
      </CommonStyles.TypographyUI>

      <div className="flex items-center gap-4 py-6 border-b border-common-divider">
        <div className="border border-common-orange500 rounded-full w-12 h-12 flex justify-center items-center">
          {/* <img className="w-7" src={CommonImages.Doctor} alt={CommonImages.Doctor} /> */}A
        </div>
        <div className="grow">
          <CommonStyles.InputUI className="h-12" variant="filled" placeholder="Viết bình luận..." />
        </div>
        <CommonStyles.ButtonUI className="h-12">Đăng</CommonStyles.ButtonUI>
      </div>

      {/* Comment */}
      <div className="grid grid-flow-col gap-4">
        <div className="border border-common-orange500 rounded-full w-12 h-12 flex justify-center items-center">A</div>

        <div className="flex flex-col gap-3">
          <div className="flex gap-4 items-center pt-3">
            <CommonStyles.TypographyUI className="text-lg text-common-maintext">Nguyen T.Phuong</CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI className="text-common-subtext text-base">&#x2022; 2 ngày trước</CommonStyles.TypographyUI>
          </div>

          <CommonStyles.TypographyUI className="text-common-maintext">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
            since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim book. It has survived not only five
            centuries, but also the leap into electronic... Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a
            type specim book. It has survived not only five centuries, but also the leap into electronic...
          </CommonStyles.TypographyUI>

          <div className=" flex items-center gap-2 cursor-pointer">
            <CommonIcons.IconReply />
            <CommonStyles.TypographyUI className="text-base text-common-cyan500">Trả lời</CommonStyles.TypographyUI>
          </div>
        </div>
      </div>
    </div>
  );
}
