import CommonImages from "@base/assets/images";
import TypographyUI from "../Typography";
import { limitString } from "@base/helpers/common";

interface IProps {}
export default function CardOutstanding(props: IProps) {
  //! state
  return (
    <div className="flex gap-4">
      <div>
        <img className="w-32 rounded-lg" src={CommonImages.DrafInforArticle} alt={CommonImages.DrafInforArticle} />
      </div>

      <div className="flex flex-col gap-2">
        <TypographyUI use="paragraph" className="text-common-maintext text-base font-normal !m-0">
          {limitString("Trẻ bị ngộ độc thức ăn nên làm gì? 4 nguyên tắc cần nắm rõ", 50)}
        </TypographyUI>
        <TypographyUI className="text-common-subtext">15-05-2023</TypographyUI>
      </div>
    </div>
  );
}
