import CommonImages from "@base/assets/images";
import TypographyUI from "../Typography";
import { Divider } from "antd";
import CommonIcons from "@base/assets/icons";
import ButtonUI from "../Button";
import themeColor from "@base/themes/colorTheme";

interface IProps {
  title?: string;
  date?: string;
  content?: string;
  cardColor?: string;
}
export default function CardArticle(props: IProps) {
  //! state
  const { title, date, content, cardColor } = props;
  //! function

  //! render
  return (
    <div className={`flex flex-col gap-4 p-6 rounded-2xl border-common-divider border shadow-lg shadow-common-disable bg-[${cardColor}]`}>
      <img className="rounded-lg" src={CommonImages.DrafInforArticle} alt={CommonImages.DrafInforArticle} />
      <TypographyUI className="text-base font-medium text-common-maintext">{title}</TypographyUI>

      <TypographyUI className="text-common-subtext text-[12px]">{date}</TypographyUI>

      <TypographyUI use="paragraph" className="text-[14px] line-clamp-1 font-normal text-common-subtext">
        {content}
      </TypographyUI>

      <Divider className="m-0" />
      <div>
        <ButtonUI
          className="text-common-orange text-base font-medium !hover:bg-white !hover:text-common-orange"
          type="text"
          icon={<CommonIcons.IconArrowRight fillColor={themeColor.orange} />}
          iconPosition="end"
        >
          Đọc thêm
        </ButtonUI>
      </div>
    </div>
  );
}
