import CommonIcons from "@base/assets/icons";
import { categories } from "@base/constants";
import { LocalKey } from "@base/constants/LocalKey";
import { ECurrency, EPromotionUnit, EStatusCode } from "@base/enums";
import { getErrorMsg } from "@base/helpers/common";
import useParseOption from "@base/hooks/useParseOptions";
import useToggle from "@base/hooks/useToggle";
import { IAppointment } from "@base/modules/appointment/appointment.interface";
import appointmentService from "@base/modules/appointment/appointmentService";
import useCreateAppointment from "@base/modules/appointment/hooks/useCreateAppointment";
import useOrder from "@base/modules/order/hooks/useOrder";
import { IPayloadOrder } from "@base/modules/order/interface/IPayloadOrder";
import orderService from "@base/modules/order/orderService";
import useCalCulateFee from "@base/modules/patientSpecialPackage/hooks/useCalculateFee";
import useGetPromotionList from "@base/modules/promotion/hooks/useGetPromotionList";
import IFilterPromotion from "@base/modules/promotion/interface/IFilterPromotion.interface";
import { IPromotion } from "@base/modules/promotion/interface/promotion.interface";
import useGetUserInfo from "@base/modules/user/account/hooks/useGetUserInfo";
import { baseRoutes } from "@base/routes/baseRoutes";
import { IDataLocalAppointment } from "@base/types";
import CommonStyles from "@components/CommonStyles";
import CardDiscount from "@components/CommonStyles/CardDiscount";
import Payment from "@components/Payment";
import { Divider } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import useGetPromotionByType from "@base/modules/promotion/hooks/useGetPromotionByType";
import IFilterPromotionByType from "@base/modules/promotion/interface/IFilterPromotionByType.interface";
import ModalVerifyUseCode from "./ModalStep3/ModalVerifyUseCode";
import useCalCulateFeeAppt from "@base/modules/appointment/hooks/useCalculateFeeAppt";
import promotionService from "@base/modules/promotion/promotionService";
import ModalListDiscount from "./ModalStep3/ModalListDiscount";
import { useTranslation } from "react-i18next";
import useGetSiteByCode from "@base/modules/site/hooks/useGetSiteByCode";

export default function Step3() {
  //! state
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dataStep1 = localStorage.getItem(LocalKey.BOOKING_APPOINTMENT);
  const { open, shouldRender, toggle } = useToggle();
  const { open: openVerifyUseCode, shouldRender: shouldRenderVerifyUseCode, toggle: toggleVerifyUseCode } = useToggle();
  const { open: openConfirmTransfer, shouldRender: shouldRenderConfirmTransfer, toggle: toggleConfirmTransfer } = useToggle();

  const parseDataPaymentLocal: IDataLocalAppointment = JSON.parse(String(dataStep1));
  const { data: site } = useGetSiteByCode(String(parseDataPaymentLocal?.payload?.site_code));

  const filterPromotion: IFilterPromotion = {
    lang: "vi",
    category: categories.APPT,
    site_code: parseDataPaymentLocal?.payload?.site_code,
  };
  const { getUserInfoData, handleGetUserInfo } = useGetUserInfo();
  const { data: dataPromotion, isLoading } = useGetPromotionList(filterPromotion, String(parseDataPaymentLocal?.appointment_type));
  const [promotionSelected, setPromotionSelected] = useState<{
    actual_fee: number;
    discount: number;
    other_fee: number;
    total_fee: number;
    tax: number;
  }>();
  const [discountCode, setDiscountCode] = useState("");

  const [methodPayment, setMethodPayment] = useState(null);

  const { handleCreateAppointment, error, isLoading: loadingCreateAPPT } = useCreateAppointment({ lang: "vi" });

  const { handleOrder } = useOrder("vi");

  const filterPromotionByType = { lang: "vi", for_check: true, site_code: parseDataPaymentLocal?.payload?.site_code, category: categories.APPT };
  const {
    data: dataDiscount,
    isLoading: loadingCheckDiscount,
    refetch: refetchCheck,
  } = useGetPromotionByType(filterPromotionByType, discountCode, false);
  const { handleFee } = useCalCulateFeeAppt();

  //! function
  const handleChangePromotion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueCode = event.target.value;
    setDiscountCode(valueCode);
  };

  const handleCheckDiscount = async () => {
    refetchCheck();
  };

  const handleChangeMethodPayment = (value: any) => {
    setMethodPayment(value);

    if (!value) {
      toggleConfirmTransfer();
    }
  };

  const dataPayment: IAppointment = {
    appointment_date: parseDataPaymentLocal?.appointment_date,

    appointment_type: parseDataPaymentLocal?.payload.appointment_type,
    appointment_used_services: parseDataPaymentLocal.payload.appointment_used_services,
    // appointment_used_services: {
    //   ...parseDataPaymentLocal.payload.appointment_used_services,
    //   service_id : parseDataPaymentLocal.payload &&   Number(parseDataPaymentLocal.payload.appointment_used_services[0].id)
    // },

    doctor: parseDataPaymentLocal?.payload.doctor,
    free: true,
    is_free: true,
    patient_record: parseDataPaymentLocal?.patient_record,
    symptoms: parseDataPaymentLocal?.payload.symptoms,
    site_code: parseDataPaymentLocal?.payload.site_code,
    site_name: parseDataPaymentLocal?.payload.site_name,
    site_type: parseDataPaymentLocal?.payload.site_type,
    time_table_period: parseDataPaymentLocal?.payload.time_table_period,
  };

  const handleStep = async (step: number) => {
    if (step === 4) {
      const result = await handleCreateAppointment(dataPayment);
      if (result?.status === EStatusCode.Success || result?.status === EStatusCode.Created) {
        const body: IPayloadOrder = {
          bank_code: methodPayment,
          cancel: false,
          created_time: 0,
          customer_address: String(parseDataPaymentLocal?.patient_record?.patient_address),
          customer_email: String(parseDataPaymentLocal?.patient_record?.patient_email),
          customer_name: String(parseDataPaymentLocal?.patient_record?.patient_name),
          customer_phone: String(getUserInfoData?.user_name),
          deliveryFee: 0,
          description: `Thanh toan lich hen - ${
            parseDataPaymentLocal?.payload.appointment_used_services && parseDataPaymentLocal?.payload.appointment_used_services[0].name
          } - ${parseDataPaymentLocal?.payload.appointment_date}`,
          discount_amounts: promotionSelected?.discount,
          order_time: 0,
          order_type: categories.APPT,
          real_amounts: Number(promotionSelected?.total_fee),
          ref_key: result.data.key,
          refund_amounts: 0,
          site_code: String(parseDataPaymentLocal?.payload.site_code),
          site_name: String(parseDataPaymentLocal?.payload.site_name),
          total_amounts: promotionSelected?.actual_fee,
        };

        handleOrder(body);
      }
    } else {
      navigate({
        pathname: baseRoutes.bookingAppointment,
        search: createSearchParams({
          step: String(step),
        }).toString(),
      });
    }
  };
  useEffect(() => {
    if (dataDiscount?.data && !loadingCheckDiscount) {
      toggleVerifyUseCode();
    }
  }, [loadingCheckDiscount]);

  useEffect(() => {
    handleGetUserInfo();

    const calculateFee = async () => {
      const resultFee = await handleFee(
        {
          ...dataPayment,
        },
        String(i18n.resolvedLanguage),
      );
      setPromotionSelected(resultFee?.data);
    };
    calculateFee();
  }, []);
  //! render
  const seeMoreDiscount = () => {
    return dataPromotion?.data.slice(0, 2).map((item, index) => {
      const isChecked = item.type === discountCode;
      return <CardDiscount key={index} data={item} isChecked={isChecked} setDiscountCode={setDiscountCode} />;
    });
  };

  return (
    <div className="bg-common-white p-8 rounded-s-lg flex flex-col h-full justify-between">
      <div className="flex flex-col gap-6">
        <CommonStyles.InputUI
          label="Mã giảm giá:"
          value={discountCode}
          onChange={handleChangePromotion}
          variant="filled"
          placeholder="Nhập mã giảm giá"
          suffix={
            <CommonStyles.ButtonUI
              className="h-full"
              onClick={() => {
                handleCheckDiscount();
              }}
            >
              Áp dụng
            </CommonStyles.ButtonUI>
          }
        />

        <div className="flex justify-between">
          <CommonStyles.TypographyUI>Chọn mã giảm giá</CommonStyles.TypographyUI>
          <CommonStyles.TypographyUI onClick={toggle} className="!text-common-inprogress cursor-pointer">
            Xem thêm
          </CommonStyles.TypographyUI>
        </div>
        <div className="grid grid-cols-2 gap-3">{seeMoreDiscount()}</div>

        <Divider className="my-0" />

        <div className="flex flex-col gap-3">
          <CommonStyles.TypographyUI className="text-base font-semibold">Số tiền thanh toán:</CommonStyles.TypographyUI>
          <div className="flex justify-between items-center">
            <CommonStyles.TypographyUI className="text-base text-common-subtext">Tạm tính:</CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI className="text-base text-common-maintext font-medium">
              {Number(promotionSelected?.total_fee).toLocaleString()}
              {ECurrency.VND}
            </CommonStyles.TypographyUI>
          </div>

          <div className="flex justify-between items-center">
            <CommonStyles.TypographyUI className="text-base text-common-subtext">Khuyến mại:</CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI className="text-base text-common-maintext font-medium">
              {promotionSelected?.discount.toLocaleString()}
              {ECurrency.VND}
            </CommonStyles.TypographyUI>
          </div>

          <div className="flex justify-between items-center">
            <CommonStyles.TypographyUI className="text-base text-common-subtext">Tổng giá:</CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI className="text-base !text-common-inprogress font-medium">
              {promotionSelected?.actual_fee.toLocaleString()}
              {ECurrency.VND}
            </CommonStyles.TypographyUI>
          </div>
        </div>

        {Number(promotionSelected?.actual_fee) > 0 && (
          <div className="flex flex-col gap-3">
            <CommonStyles.TypographyUI use="paragraph" className="text-base font-semibold !mb-0">
              Phương thức thanh toán:
            </CommonStyles.TypographyUI>

            <Payment setBankCode={handleChangeMethodPayment} listPayment={site?.data.supported_payment_methods} />
          </div>
        )}
      </div>

      <div>
        <Divider className="my-4 border-common-disable" />

        <div className="flex justify-end items-center">
          <div className="flex items-center gap-4">
            <CommonStyles.ButtonUI
              onClick={() => {
                navigate(-1);
              }}
              className="h-12 font-medium text-base bg-common-orange100 text-common-orange hover:!bg-common-orange200 hover:!text-common-orange"
              icon={<CommonIcons.IconArrowLeft />}
              iconPosition="start"
            >
              Quay lại
            </CommonStyles.ButtonUI>

            <CommonStyles.ButtonUI
              onClick={() => {
                handleStep(4);
              }}
              className="h-12 font-medium text-base"
              icon={<CommonIcons.IconArrowRight />}
              // disabled={methodPayment === null && totalAmount > 0}
              iconPosition="end"
            >
              Thanh toán
            </CommonStyles.ButtonUI>
          </div>
        </div>
      </div>

      {shouldRenderVerifyUseCode && (
        <CommonStyles.ModalConfirmationUI
          title={<CommonStyles.TypographyUI className="!text-lg font-semibold">Tìm thấy mã giảm giá</CommonStyles.TypographyUI>}
          type="normal"
          open={openVerifyUseCode}
          toggle={toggleVerifyUseCode}
          content={
            <ModalVerifyUseCode
              filterPromotionByType={filterPromotionByType}
              discount={dataDiscount?.data}
              toggle={toggleVerifyUseCode}
              setDiscount={setPromotionSelected}
              dataPayment={dataPayment}
            />
          }
          hiddenAction
        />
      )}

      {shouldRender && (
        <CommonStyles.ModalConfirmationUI
          title="Chọn mã giảm giá"
          width={700}
          type="normal"
          open={open}
          toggle={toggle}
          content={<ModalListDiscount type={categories.APPT} setDiscountCode={setDiscountCode} discountCode={discountCode} toggle={toggle} />}
          hiddenAction
        />
      )}

      {shouldRenderConfirmTransfer && (
        <CommonStyles.TransferInformationDialog
          transferContent={
            "Thanh toán lịch hẹn - " +
            (parseDataPaymentLocal?.payload.appointment_date
              ? dayjs(parseDataPaymentLocal?.payload.appointment_date, "YYYY-MM-DD").format("DD-MM-YYYY") + " - "
              : "") +
            (parseDataPaymentLocal?.patient_record?.patient_name ?? "[Hồ sơ]") +
            " - " +
            (parseDataPaymentLocal?.patient_record?.patient_phone_number ?? "[SĐT]")
          }
          toggle={toggleConfirmTransfer}
          open={openConfirmTransfer}
        />
      )}
    </div>
  );
}
