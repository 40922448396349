import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import IFilterSiteSpecialPackage from "./interface/filterSiteSpecialPackage";

class SiteSpecialPackage {
  getAllForApp(query?: IFilterSiteSpecialPackage) {
    return httpService.get(apiService.utils.siteSpecialPackage.getAllForApp, {
      params: query,
    });
  }
  getOne(query: { site_code: string; package_code: string }) {
    return httpService.get(`${apiService.utils.siteSpecialPackage.getOne}`, { params: query });
  }
}

export default new SiteSpecialPackage();
