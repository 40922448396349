import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { orders } from "@base/constants";
import useGetListCommon from "@base/modules/common/hooks/useGetListCommon";
import { backToTop } from "@base/helpers/common";
import { baseRoutes } from "@base/routes/baseRoutes";
import { initialPage } from "@base/themes/colorTheme";
import CommonStyles from "@components/CommonStyles";
import Article from "@components/Home/Article/Article";
import ConnectHome from "@components/Home/ConnectHome";
import DoctorHome from "@components/Home/DoctorHome";
import DownloadApp from "@components/Home/DownloadApp";
import Feedback from "@components/Home/Feedback";
import PackageService from "@components/Home/PackageService";
import PartnerConnect from "@components/Home/PartnerConnect";
import SearchHome from "@components/Home/SearchHome";
import ServiceHome from "@components/Home/ServiceHome";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { responseUI } from "@base/themes/response";
import CarouselHome from "@components/Home/Carousel";
import { useSelector } from "react-redux";
import { RootState } from "@base/store/store";
import { FloatButton } from "antd";

export default function Home() {
  //! state
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dataCommon = useSelector((state: RootState) => state.appCommonSlice.dataCommon);

  //! function

  useEffect(() => {
    backToTop();
  }, []);
  //! render
  const renderOrder = () => {
    return (
      <div className="grid grid-cols-2 gap-4 xl:gap-0 xl:grid-cols-7 relative">
        {orders.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className="px-3 flex flex-col text-center items-center">
                <div>
                  <item.Icon />
                </div>
                <div className=" lg:px-20 xl:px-0">
                  <CommonStyles.TypographyUI use="title" level={5} className="!text-common-maintext !font-medium text-nowrap  text-base lg:!text-lg">
                    {t(`${item.title}`)}
                  </CommonStyles.TypographyUI>
                  <CommonStyles.TypographyUI use="paragraph" className=" text-common-subtext text-sm">
                    {t(`${item.subTitle}`)}
                  </CommonStyles.TypographyUI>
                </div>
              </div>

              {index !== orders.length - 1 && (
                <div className="xl:flex justify-center hidden">
                  <div className="absolute top-1/4 ">
                    <CommonIcons.IconArrowOrderHome />
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  };
  return (
    <div>
      <div className={` bg-common-white`}>
        {/* top */}
        <div className={`${responseUI.screen}`}>
          <CarouselHome />
        </div>
        {/* search */}
        {/* <div className="absolute w-full -bottom-[15%] ">
          <SearchHome />
        </div> */}
      </div>
      {/* order */}
      <div className="hidden md:block bg-common-white">
        <div className={` py-5 ${responseUI.screen} pt-10 ${responseUI.padding}`}>
          <CommonStyles.TypographyUI use="title" className={`${responseUI.text.title} text-center`}>
            {t("home.BookingFast")}
          </CommonStyles.TypographyUI>

          <CommonStyles.TypographyUI use="paragraph" className="text-common-subtext text-sm lg:!text-base  text-center !mb-14">
            {t("home.subBookingFast")}
          </CommonStyles.TypographyUI>
          {renderOrder()}
        </div>
      </div>

      {/* service */}

      <div>
        <ServiceHome />
      </div>

      {/* connect */}
      <div>
        <ConnectHome />
      </div>
      {/* Doctor */}
      <div>
        <DoctorHome />
      </div>
      <div>
        <PackageService />
      </div>
      {/* Aticle */}
      <div>{/* <Article /> */}</div>
      {/* download app */}
      <div>
        <DownloadApp dataCommon={dataCommon} />
      </div>
      {/* Feedback */}
      <div>
        <Feedback />
      </div>
      {/* Partner */}
      <div>
        <PartnerConnect />
      </div>
      <FloatButton.BackTop
        onClick={() => backToTop("smooth")}
        visibilityHeight={900}
        type="primary"
        style={{ insetInlineEnd: 30, insetBlockEnd: 30 }}
      />
    </div>
  );
}
