import CommonStyles from "@components/CommonStyles";

export default function TagsKey() {
  //! state
  const tags = ["Review", "Ngộ độc thực phẩm", "Y tế", "Sức khỏe", "Trẻ em", "Sơ cứu"];
  //! function
  //! render
  const renderTags = () => {
    return tags.map((tag, index) => {
      return <CommonStyles.TagUI className="px-4 py-2 opacity-80 m-0" tagName={tag} key={index} />;
    });
  };
  return <div className="flex flex-wrap gap-3">{renderTags()}</div>;
}
