import { CloseCircleTwoTone } from "@ant-design/icons";
import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { optionProvince } from "@base/constants";
import { EDrugOrderStatus } from "@base/enums";
import useCheckLogin from "@base/hooks/useCheckLogin";
import { IDistrict } from "@base/modules/administrative/administrative.interface";
import administrativeService from "@base/modules/administrative/administrativeService";
import useGetDistrictByProvinceCode from "@base/modules/administrative/hooks/useGetDistrictByProvinceCode";
import { IDrugOrder } from "@base/modules/drugOrder/drugOrder.interface";
import drugOrderService from "@base/modules/drugOrder/drugOrderService";
import { IState } from "@base/modules/site/site.interface";
import useGetState from "@base/modules/state/hooks/useGetState";
import { IPatientRecord, IPatientRecordData } from "@base/modules/user/profile/profile.interface";
import profileService from "@base/modules/user/profile/profileService";
import { baseRoutes } from "@base/routes/baseRoutes";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";
import { RootState } from "@base/store/store";
import themeColor, { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import { Divider, Image } from "antd";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Medicine() {
  //! state
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const breadcrumItem = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      title: "Mua thuốc",
    },
  ];
  const auth = useSelector((state: RootState) => state.appCommonSlice.auth);

  const { handleCheckLogin } = useCheckLogin();
  const [patientRecords, setPatientRecords] = useState<IPatientRecordData[]>([]);

  const [notes, setNotes] = useState<string | undefined>();
  const [selectedPatientRecordCode, setSelectedPatientRecordCode] = useState<string | undefined | null>();
  const [selectedPatientRecord, setSelectedPatientRecord] = useState<IPatientRecordData | undefined | null>();

  const { data: states } = useGetState();
  const [districts, setDistricts] = useState<IDistrict[]>([]);

  const [files, setFiles] = useState<any[]>([]);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    accept: { "image/*": [".jpeg", ".png", ".jpeg", ".pdf"] },
    maxSize: 5242880,
    onDrop: (_files) => {
      if (files.length + _files.length <= 5) {
        files.push(..._files);
      }

      setFiles(files);
      setFilesKey(filesKey + 1);
    },
  });

  const [filesKey, setFilesKey] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //! function
  useEffect(() => {
    if (auth) {
      getPatientRecords();
    }
  }, [auth]);

  useEffect(() => {
    const _p = patientRecords.find((p) => {
      return p.patient_record?.code === selectedPatientRecordCode;
    });

    setSelectedPatientRecord(_p);
    if (selectedPatientRecordCode && _p?.patient_record?.administrative_province_code) {
      getDistricts(_p.patient_record.administrative_province_code);
    }
  }, [selectedPatientRecordCode]);

  const getDistricts = async (province_code: string) => {
    try {
      const resp = await administrativeService.getListDistrictByProvinceCode(province_code);

      setDistricts(resp.data ?? []);
    } catch (error) {}
  };

  const getPatientRecords = async () => {
    try {
      const resp = await profileService.getByUserKey();

      resp.data.sort((a: IPatientRecordData, b: IPatientRecordData) => {
        if (a.patient_record?.default_record) {
          return -1;
        }
        return 0;
      });

      setPatientRecords(resp.data);

      if (!selectedPatientRecordCode && resp.data.length) {
        setSelectedPatientRecordCode(resp.data[0].patient_record?.code);
      }
    } catch (error) {}
  };

  const onRemoveFile = (index: number) => {
    const _fs: any[] = [];
    _fs.push(...files);

    _fs.splice(index, 1);

    setFiles(_fs);
    setFilesKey(filesKey + 1);
  };

  const onSelectPatientRecord = () => {
    handleCheckLogin();
  };

  const onSubmit = async () => {
    handleCheckLogin();

    if (!selectedPatientRecord) {
      dispatch(setAlertMessage({ type: "warning", message: t("Notification"), description: "Vui lòng chọn hồ sơ để tiếp tục!" }));
    }

    const payload: IDrugOrder = {
      notes: notes,
      patient_record: selectedPatientRecord?.patient_record,
      status: EDrugOrderStatus.PENDING,
    };

    try {
      setIsLoading(true);
      const resp = await drugOrderService.create(payload, Number(selectedPatientRecord?.patient_record?.patient_ethic) === 25 ? "vi" : "en");

      if (resp.data && files.length) {
        await drugOrderService.uploadImage(resp.data.drug_order_code as string, files);
      }

      dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: "Gửi đơn thuốc thành công!" }));

      navigate(baseRoutes.userPrescriptionDetail.replace(":code", resp.data.drug_order_code ? (resp.data.drug_order_code as string) : ""));
    } catch (error) {
      dispatch(setAlertMessage({ type: "error", message: t("Notification"), description: "Gửi đơn thuốc không thành công!" }));
    } finally {
      setIsLoading(false);
    }
  };

  //! render
  const previewfiles = files.length ? (
    <Image.PreviewGroup>
      {files.map((file: any, idx: number) => (
        <div key={idx} className="w-fit relative">
          <Image key={idx} width={100} className="max-h-[200px]" src={window.URL.createObjectURL(file)} />
          <span className="absolute -right-1 -top-2 cursor-pointer" onClick={() => onRemoveFile(idx)}>
            <CloseCircleTwoTone twoToneColor={themeColor.negative} />
          </span>
        </div>
      ))}
    </Image.PreviewGroup>
  ) : null;

  return (
    <div>
      <div className="w-full bg-common-white">
        <div className={`${responseUI.screen} ${responseUI.padding} relative py-10 lg:py-14 flex flex-col`}>
          {/* <CommonStyles.BreadcrumbUI items={breadcrumItem} /> */}

          <div className="text-center">
            <CommonStyles.TypographyUI use="title" level={3} className={`${responseUI.text.title}`}>
              đặt thuốc theo đơn
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI use="paragraph" className="text-common-subtext text-base">
              Tải lên đơn thuốc và chúng tôi sẽ giao thuốc cho bạn
            </CommonStyles.TypographyUI>
          </div>

          <div className="absolute right-0 bottom-0">
            <CommonIcons.IconHeart width={150} height={150} />
          </div>
        </div>
      </div>

      <div className={`${responseUI.screen} ${responseUI.padding} py-10`}>
        <div className="p-6 rounded-2xl  bg-common-white flex flex-col lg:flex-row lg:gap-3 gap-9">
          <div className="flex flex-col gap-5 basis-2/3 xl:basis-3/4">
            <div
              {...getRootProps({ className: "dropzone" })}
              className="flex flex-col justify-center items-center border border-dashed py-9 rounded-2xl cursor-pointer"
            >
              <input {...getInputProps()} />
              <div>
                <img src={CommonImages.imageUploadMedicine} alt={CommonImages.imageUploadMedicine} />
              </div>
              <div className="text-center">
                <CommonStyles.TypographyUI use="paragraph" className="font-semibold !mb-1">
                  Kéo thả hoặc <span className="text-common-orange">Chọn file</span> để tải lên
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI className="text-xs text-common-[#AAAFB5]">
                  Tối đa 5 hình và dung lượng không quá 5MB
                </CommonStyles.TypographyUI>
              </div>
            </div>

            <div key={filesKey} className="w-full flex gap-2 justify-center items-center">
              {previewfiles}
            </div>

            <div>
              <CommonStyles.TextAreaUI
                label="Ghi chú"
                variant="filled"
                placeholder="Ghi chú về đơn thuốc hoặc các triệu chứng khác..."
                value={notes}
                onChange={(value) => setNotes(value.target.value)}
              />
              <Divider className="!mb-2" />
            </div>

            <div
              className={
                auth
                  ? "flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:justify-start gap-2"
                  : "flex items-center justify-between gap-2"
              }
            >
              <CommonStyles.TypographyUI className="font-medium text-base">Thông tin liên hệ</CommonStyles.TypographyUI>

              {auth ? (
                <CommonStyles.SelectUI
                  rootClass="max-sm:w-full"
                  className="w-72 max-sm:w-full float-right h-12"
                  value={selectedPatientRecordCode}
                  variant="filled"
                  placeholder="Chọn hồ sơ"
                  options={patientRecords.map((patientRecord: IPatientRecordData) => {
                    return { label: patientRecord.patient_record?.patient_name, value: patientRecord.patient_record?.code };
                  })}
                  onChange={(value) => setSelectedPatientRecordCode(value as string)}
                />
              ) : (
                <CommonStyles.ButtonUI type="text" className="text-common-orange text-base font-medium" onClick={onSelectPatientRecord}>
                  Chọn hồ sơ
                </CommonStyles.ButtonUI>
              )}
            </div>

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-2">
              <div>
                <CommonStyles.InputUI label="Họ và tên" variant="filled" disabled value={selectedPatientRecord?.patient_record?.patient_name} />
              </div>

              <div>
                <CommonStyles.InputUI
                  label="Số điện thoại"
                  variant="filled"
                  disabled
                  value={selectedPatientRecord?.patient_record?.patient_phone_number}
                />
              </div>

              <div>
                <CommonStyles.SelectUI
                  label="Tỉnh/ Thành Phố"
                  variant="filled"
                  disabled
                  options={
                    states?.data
                      ? states?.data.map((state: IState) => {
                          return { label: state.name, value: state.code };
                        })
                      : []
                  }
                  value={selectedPatientRecord?.patient_record?.administrative_province_code}
                />
              </div>

              <div>
                <CommonStyles.SelectUI
                  label="Quận/ Huyện"
                  variant="filled"
                  disabled
                  options={
                    districts
                      ? districts.map((district: IDistrict) => {
                          return { label: district.name, value: district.code };
                        })
                      : []
                  }
                  value={selectedPatientRecord?.patient_record?.administrative_district_code}
                />
              </div>

              <div className="xl:col-span-2">
                <CommonStyles.InputUI
                  label="Địa chỉ chi tiết"
                  variant="filled"
                  disabled
                  value={selectedPatientRecord?.patient_record?.patient_address}
                />
              </div>
            </div>

            <div className="flex justify-center items-center">
              <CommonStyles.ButtonUI className="h-12" onClick={onSubmit} loading={isLoading}>
                Gửi đơn thuốc
              </CommonStyles.ButtonUI>
            </div>
          </div>

          <div className="basis-1/3 xl:basis-1/4 flex flex-col gap-4 px-3 lg:border-l">
            <div className="bg-common-orange400 p-6 rounded-2xl text-center flex flex-col gap-3">
              <CommonStyles.TypographyUI className="text-lg font-semibold text-common-white">Bạn chưa được kê đơn thuốc?</CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI className="text-common-white text-base">
                Đặt lịch tư vấn với Bác sĩ để được giải đáp các vấn đề liên quan tới sức khỏe của bạn một cách hiệu quả và nhanh chóng
              </CommonStyles.TypographyUI>
              <div>
                <CommonStyles.ButtonUI
                  icon={<CommonIcons.IconVideoFilled />}
                  onClick={() => {
                    navigate(baseRoutes.bookingOnline);
                  }}
                  className="h-12 bg-common-white text-common-orange font-medium hover:!bg-common-orange100 hover:!text-common-orange600
                "
                >
                  Đặt lịch tư vấn
                </CommonStyles.ButtonUI>
              </div>
            </div>

            <div className="py-4 px-6 flex gap-3 items-center rounded-xl bg-gradient-to-r from-common-orange100 to-common-orange50 border border-common-orange">
              <img src={CommonImages.Giffbox} alt={CommonImages.Giffbox} />
              <CommonStyles.TypographyUI className="text-orange-500 font-medium text-base">
                Tặng ngay Voucher 30K cho đơn thuốc đầu tiên trên 365 Medihome
              </CommonStyles.TypographyUI>
            </div>

            <div>
              <CommonStyles.TypographyUI use="paragraph" className="text-[#414C58] text-base font-medium !mb-6">
                Hướng dẫn đặt thuốc theo đơn
              </CommonStyles.TypographyUI>
              <div className="flex flex-col gap-6">
                <div className="flex items-center gap-4">
                  <img src={CommonImages.StepUpload} alt={CommonImages.StepUpload} />
                  <div>
                    <CommonStyles.TypographyUI use="paragraph" className="!mb-0">
                      Bước 1
                    </CommonStyles.TypographyUI>
                    <CommonStyles.TypographyUI className="text-common-subtext text-xs">Tải lên hình ảnh đơn thuốc của bạn</CommonStyles.TypographyUI>
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <img src={CommonImages.StepCall} alt={CommonImages.StepCall} />
                  <div>
                    <CommonStyles.TypographyUI use="paragraph" className="!mb-0">
                      Bước 2
                    </CommonStyles.TypographyUI>
                    <CommonStyles.TypographyUI className="text-common-subtext text-xs">
                      Thuốc của bạn sẽ được giao nhanh trong hôm nay
                    </CommonStyles.TypographyUI>
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <img src={CommonImages.StepDelivery} alt={CommonImages.StepDelivery} />
                  <div>
                    <CommonStyles.TypographyUI use="paragraph" className="!mb-0">
                      Bước 3
                    </CommonStyles.TypographyUI>
                    <CommonStyles.TypographyUI className="text-common-subtext text-xs">
                      Dước sĩ sẽ liên hệ với bạn để tư vấn và xác nhận đơn thuốc
                    </CommonStyles.TypographyUI>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <CommonStyles.TypographyUI use="paragraph" className="text-[#414C58] text-base font-medium !mb-2">
                Lưu ý: Đơn thuốc của bạn cần
              </CommonStyles.TypographyUI>

              <ul className={`list-disc pl-9 py-2 text-common-subtext flex flex-col text-base ${responseUI.text.default}`}>
                <li>Nguyên vẹn và còn hiệu lực (trong 5 ngày)</li>
                <li>Hình ảnh đơn thuốc rõ nét và không bị cắt xén, mất góc</li>
                <li>Đủ và rõ thông tin của đơn vị phát hành đơn thuốc, chuẩn đoán của bác sĩ, tên thuốc kèm số lượng và liều dùng</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
