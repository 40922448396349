import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { getAvatarLink, locateToLocationOnGGMap } from "@base/helpers/common";
import useCheckLogin from "@base/hooks/useCheckLogin";
import useGetSiteByCode from "@base/modules/site/hooks/useGetSiteByCode";
import { ISite } from "@base/modules/site/site.interface";
import siteService from "@base/modules/site/siteService";
import { baseRoutes } from "@base/routes/baseRoutes";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import TabDoctor from "@components/OrderFacility/[id]/TabDoctor";
import TabInfo from "@components/OrderFacility/[id]/TabInfo";
import TabPackageService from "@components/OrderFacility/[id]/TabPackageService";
import TabSpecialist from "@components/OrderFacility/[id]/TabSpecialist";
import { tab } from "@testing-library/user-event/dist/tab";
import { TabsProps } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";

export default function OrderFacilityDetail() {
  //! state
  const { t } = useTranslation();
  const { id: site_id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabActive = searchParams.get("tab");
  const site_code = searchParams.get("site_code");
  const [activeKey, setActiveKey] = useState<string>(String(tabActive));
  const breadcrumItem = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      href: baseRoutes.orderFacility,
      title: "Đặt lịch",
    },
    {
      title: "Khám tại cơ sở y tế",
    },
  ];
  const { data: dataSite, isLoading } = useGetSiteByCode(String(site_code));
  const site = dataSite?.data as ISite;

  const tabs: TabsProps["items"] = [
    {
      key: "1",
      label: t("Information"),
      children: <TabInfo site={site} />,
    },
    {
      key: "2",
      label: t("Doctors"),
      children: <TabDoctor site={site} />,
    },
    // {
    //   key: "3",
    //   label: t("Specializations"),
    //   children: <TabSpecialist site={site} />,
    // },
    {
      key: "4",
      label: t("ServicePackages"),
      children: <TabPackageService site={site} />,
    },
  ];

  //! function

  const handleBooking = () => {
    navigate({
      pathname: baseRoutes.bookingAppointment,
      search: createSearchParams({
        site_code: String(site_code),
      }).toString(),
    });
  };

  useEffect(() => {
    setActiveKey(String(tabActive));
  }, [tabActive]);

  //! render
  return (
    <div className="bg-common-white">
      <div className={`bg-common-white  pb-8 relative  lg:py-5 flex flex-col justify-between ${responseUI.screen}`}>
        <div
          className="h-[200px] lg:h-[368px] relative"
          style={{
            backgroundImage: `url(${getAvatarLink(site?.avatar ?? [], "banner")}), url(${CommonImages.BannerDefaultSite}) `,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "cover",
          }}
        >
          <div className="lg:hidden bg-common-background p-3 rounded-2xl absolute -bottom-10 left-1/2 -translate-x-1/2">
            <div className="w-[50px]">
              <img
                className="aspect-square object-contain"
                src={getAvatarLink(site?.avatar ?? [])}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = CommonImages.SiteDefault;
                }}
              />
              {/* <CommonStyles.ImageUI className="lg:aspect-square " src={getAvatarLink(site?.avatar ?? [])} fallback={CommonImages.SiteDefault} /> */}
            </div>
          </div>
        </div>

        <div className="flex flex-wrap lg:flex-nowrap gap-7 p-4  bg-common-white mt-10 lg:mt-0">
          <div className="hidden basis-1/3 lg:basis-1/4 lg:flex bg-common-background p-3 rounded-2xl w-full">
            <div className="flex justify-center items-center ">
              <CommonStyles.ImageUI
                className="lg:aspect-square xl:w-[237px] object-contain"
                src={getAvatarLink(site?.avatar ?? [])}
                fallback={CommonImages.SiteDefault}
              />
            </div>
          </div>

          <div className="lg:basis-3/4">
            <div className="flex flex-col justify-center h-full gap-6">
              <CommonStyles.TypographyUI className="font-medium lg:text-xl text-common-maintext">{site?.name}</CommonStyles.TypographyUI>

              <div className="flex flex-col gap-3">
                {site?.address ? (
                  <div className="flex items-center lg:gap-3">
                    <div>
                      <CommonIcons.IconLocation />
                    </div>
                    <CommonStyles.TypographyUI className="lg:text-base text-common-subtext">{site.address}</CommonStyles.TypographyUI>
                  </div>
                ) : null}

                <div className="flex items-center lg:gap-3">
                  <div>
                    <CommonIcons.IconNetWork />
                  </div>
                  <NavLink className="text-common-inprogress underline" to={String(site?.description_link)}>
                    {site?.description_link}
                  </NavLink>
                </div>
              </div>

              <div className="flex items-center justify-center lg:justify-start gap-4">
                <CommonStyles.ButtonUI onClick={handleBooking} className="lg:h-10 font-medium w-32">
                  {t("home.bookAppointment")}
                </CommonStyles.ButtonUI>
                <CommonStyles.ButtonUI
                  onClick={() => (site.lon && site.lat ? locateToLocationOnGGMap({ lon: site.lon, lat: site.lat }) : {})}
                  className="lg:h-10 font-medium bg-common-orange100 text-common-mainColor hover:!bg-common-orange200 hover:!text-common-mainColor w-32"
                >
                  {t("Direct")}
                </CommonStyles.ButtonUI>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="absolute right-0 bottom-0">
          <CommonIcons.IconHeart width={284} height={267} />
        </div> */}
      </div>
      <div className="bg-common-background">
        <div className={`bg-common-background py-4 ${responseUI.screen}`}>
          <CommonStyles.TabsUI
            activeKey={activeKey}
            onChange={(value) => {}}
            items={tabs}
            renderTabBar={(props) => (
              <CustomTabBar tabs={tabs} setActiveKey={setActiveKey} setSearchParams={setSearchParams} site_code={String(site_code)} {...props} />
            )}
          />
        </div>
      </div>
    </div>
  );
}

const CustomTabBar = ({
  activeKey,
  setActiveKey,
  tabs,
  setSearchParams,
  site_code,
}: {
  activeKey: string;
  setActiveKey: Dispatch<SetStateAction<string>>;
  tabs: TabsProps["items"];
  setSearchParams: any;
  site_code: string;
}) => {
  //! state

  //! function
  // Handle tab click event
  const handleTabClick = (key: any) => {
    setActiveKey(key);
    setSearchParams({ site_code: site_code, tab: key });
  };

  //! render
  const renderTab = () => {
    return tabs?.map((item, index) => {
      const active = activeKey === `${item.key}` ? "text-common-orange bg-common-orange100" : "";
      return (
        <div
          className={`py-3 lg:px-10 text-common-maintext font-medium text-xs lg:text-base text-center lg:gap-3 rounded-lg cursor-pointer grow custom-tab  ${active}`}
          onClick={() => handleTabClick(item.key)}
          key={index}
        >
          {item.icon}
          {item.label}
        </div>
      );
    });
  };
  return <div className="custom-tab-bar bg-white p-3 rounded-2xl flex justify-between">{renderTab()}</div>;
};
