import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { getAvatarLink } from "@base/helpers/common";
import useGetDoctorCodeLess from "@base/modules/doctor/hooks/useGetDoctorCodeLess";
import { baseRoutes } from "@base/routes/baseRoutes";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import CommonStyles from "@components/CommonStyles";
import ImageUI from "@components/CommonStyles/Image/Index";
import TabInfo from "@components/Doctor/[id]/TabInfo";
import TabService from "@components/Doctor/[id]/TabService";
import { TabsProps } from "antd";
import { useState } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";

export default function DoctorDetail() {
  //! state
  const { id } = useParams();
  const navigate = useNavigate();
  const breadcrumItem = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      href: baseRoutes.orderFacility,
      title: "Đặt lịch",
    },
    {
      href: baseRoutes.orderFacility,
      title: "Khám tại cơ sở y tế",
    },
    {
      href: `${baseRoutes.orderFacility}/1`,
      title: "PKĐK. Dr.Binh Tele Clinic",
    },
    {
      title: "BS. Nguyễn Viết Nhân",
    },
  ];

  const { data: doctorDetail, isLoading } = useGetDoctorCodeLess(String(id));
  const dataDoctor = doctorDetail?.data;
  const [activeKey, setActiveKey] = useState("1");

  const tabs: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin chi tiết",
      children: <TabInfo doctor={dataDoctor} />,
    },
    // {
    //   key: "2",
    //   label: "Dịch vụ",
    //   children: <TabService doctor={dataDoctor} />,
    // },
  ];
  //! function
  const handleBooking = (type: string) => {
    navigate({
      pathname: baseRoutes.bookingAppointment,
      search: createSearchParams({
        doctor_code: String(dataDoctor?.doctor_code),
        site_code: String(dataDoctor?.site_code),
        specialization_code: String(dataDoctor?.specialization?.specialization_code),
        type: String(type),
      }).toString(),
    });
  };
  //! render
  const CustomTabBar = ({ activeKey, setActiveKey }: any) => {
    // Handle tab click event
    //! state
    //! function

    const handleTabClick = (key: any) => {
      setActiveKey(key);
    };
    //! render

    const renderTab = () => {
      return tabs.map((item, index) => {
        const active = activeKey === `${item.key}` ? "text-common-orange bg-common-orange100" : "";
        return (
          <div
            className={`py-3 lg:px-10 text-common-maintext font-medium lg:text-base text-center gap-3 rounded-lg cursor-pointer grow custom-tab  ${active}`}
            onClick={() => handleTabClick(item.key)}
            key={index}
          >
            {item.icon}
            {item.label}
          </div>
        );
      });
    };
    return <div className="custom-tab-bar bg-white p-3 rounded-2xl flex">{renderTab()}</div>;
  };

  if (isLoading) {
    return <CommonStyles.LoadingUI />;
  }
  return (
    <div className="bg-common-white">
      <div className={`bg-common-white pb-8 relative ${responseUI.screen} lg:py-5 flex flex-col justify-between`}>
        {/* <CommonStyles.BreadcrumbUI items={breadcrumItem} /> */}

        <div className="flex flex-col md:flex-row gap-3 lg:gap-7 p-4 rounded-2xl bg-common-white lg:mt-9">
          <div className=" bg-common-background p-3 rounded-2xl flex items-center justify-center">
            <div className="relative md:w-[200px] lg:w-[288px] ">
              <ImageUI
                className="rounded-xl aspect-square"
                src={getAvatarLink(dataDoctor?.avatar ?? [])}
                fallback={CommonImages.DefaultDoctorAvatarSquare}
              />
              <CommonStyles.RatingUI className="bg-common-white absolute top-2 right-3" maxRating={5} rating={dataDoctor?.rating_point ?? 0} />
            </div>
          </div>

          <div className="grow p-3 md:p-0">
            <div className="flex flex-col justify-center gap-3 h-full">
              <CommonStyles.TypographyUI className="font-medium text-lg lg:text-xl text-common-maintext">
                {dataDoctor?.doctor_name}
              </CommonStyles.TypographyUI>
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-3">
                  <CommonIcons.IconMedalStar />
                  <CommonStyles.TypographyUI className="lg:text-base text-common-maintext">{dataDoctor?.title?.title_name}</CommonStyles.TypographyUI>
                </div>
                <div className="flex items-center gap-3">
                  <CommonIcons.IconFacilityOutline />
                  <CommonStyles.TypographyUI className="lg:text-base text-common-maintext">{dataDoctor?.site_name}</CommonStyles.TypographyUI>
                </div>
                <div className="flex items-center gap-3">
                  <CommonIcons.IconLocalHospialOutline />
                  <CommonStyles.TypographyUI className="lg:text-base text-common-maintext">
                    {dataDoctor?.specialization?.description}
                  </CommonStyles.TypographyUI>
                </div>
              </div>

              <div className="flex flex-col md:items-center lg:flex-row  items-start gap-4">
                {dataDoctor?.online_dr && (
                  <CommonStyles.ButtonUI
                    icon={<CommonIcons.RightOutlined />}
                    iconPosition="end"
                    className={`${responseUI.button.default} font-medium py-6 lg:!py-8 bg-gradient-to-b from-[#FFB571] to-common-mainColor lg:min-w-64 justify-between`}
                    onClick={() => {
                      handleBooking("online");
                    }}
                  >
                    <div className="flex gap-2">
                      <CommonIcons.IconCameraBox />
                      <div className="flex flex-col items-start justify-center">
                        <CommonStyles.TypographyUI className="text-common-white text-base font-medium">Tư vấn online</CommonStyles.TypographyUI>
                        {Number(dataDoctor?.price_online) > 0 && (
                          <CommonStyles.TypographyUI className="text-sm text-common-white">
                            {dataDoctor?.price_online?.toLocaleString() + "đ"}
                          </CommonStyles.TypographyUI>
                        )}
                      </div>
                    </div>
                  </CommonStyles.ButtonUI>
                )}

                {dataDoctor?.offline_dr && (
                  <CommonStyles.ButtonUI
                    icon={<CommonIcons.RightOutlined />}
                    iconPosition="end"
                    className="h-12 font-medium py-6 lg:!py-8 bg-gradient-to-t from-[#2F80ED] to-[#82B3F4] lg:min-w-64 justify-between hover:!bg-gradient-to-t"
                    onClick={() => {
                      handleBooking("csyt");
                    }}
                  >
                    <div className="flex gap-2">
                      <CommonIcons.IconFacilityBox />
                      <div className="flex flex-col items-start justify-center">
                        <CommonStyles.TypographyUI className="text-common-white text-base font-medium">Tại cơ sở y tế</CommonStyles.TypographyUI>
                        {Number(dataDoctor?.price) > 0 && (
                          <CommonStyles.TypographyUI className="text-sm text-common-white">
                            {dataDoctor?.price?.toLocaleString() + "đ"}
                          </CommonStyles.TypographyUI>
                        )}
                      </div>
                    </div>
                  </CommonStyles.ButtonUI>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="absolute right-0 bottom-0">
          <CommonIcons.IconHeart width={150} height={150} />
        </div>
      </div>

      <div className="bg-common-background">
        <div className={`${responseUI.screen} py-4`}>
          <CommonStyles.TabsUI
            activeKey={activeKey}
            items={tabs}
            renderTabBar={(props, DefaultTabBar) => <CustomTabBar setActiveKey={setActiveKey} {...props} />}
          />
        </div>
      </div>
    </div>
  );
}
