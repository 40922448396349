import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { LocalKey } from "@base/constants/LocalKey";
import { backToTop } from "@base/helpers/common";
import useCheckLogin from "@base/hooks/useCheckLogin";
import { baseRoutes } from "@base/routes/baseRoutes";
import { initialPage } from "@base/themes/colorTheme";
import { responseUI } from "@base/themes/response";
import Step1 from "@components/AppointmentBooking/Step1";
import Step2 from "@components/AppointmentBooking/Step2";
import Step3 from "@components/AppointmentBooking/Step3";
import Step4 from "@components/AppointmentBooking/Step4";
import CommonStyles from "@components/CommonStyles";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function AppointmentBooking() {
  //! state
  const [searchParams] = useSearchParams();
  const breadcrumItem = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      href: baseRoutes.orderFacility,
      title: "Đặt lịch",
    },
    {
      title: "Đặt lịch khám",
    },
  ];
  const itemSteps = [
    {
      title: "Thông tin lịch khám",
    },
    {
      title: "Xác nhận thông tin",
    },
    {
      title: "Thanh toán",
    },
  ];
  const { handleCheckLogin } = useCheckLogin();
  const step = searchParams.get("step") ?? 1;

  //! function
  useEffect(() => {
    handleCheckLogin();
    return () => {
      localStorage.removeItem(LocalKey.BOOKING_APPOINTMENT);
    };
  }, []);

  useEffect(() => {
    backToTop();
  }, [step]);

  //! render
  const renderStep = () => {
    switch (step) {
      case "1":
        return <Step1 />;
      case "2":
        return <Step2 />;
      case "3":
        return <Step3 />;
      case "4":
        return <Step4 />;
      default:
        return <Step1 />;
    }
  };

  return (
    <div>
      <div className="bg-common-white">
        <div className={`bg-common-white lg:pb-8 relative ${responseUI.screen} lg:py-5 flex flex-col justify-between ${responseUI.padding}`}>
          <div className="text-center my-2 lg:my-20">
            <CommonStyles.TypographyUI use="paragraph" className={`${responseUI.text.title}`}>
              Đặt lịch khám
            </CommonStyles.TypographyUI>

            <CommonStyles.TypographyUI className="text-xs lg:text-base text-common-subtext">
              Đặt lịch khám- chữa bệnh tại 365 Medihome giúp chủ động thời gian, hạn chế tiếp xúc đông người
            </CommonStyles.TypographyUI>
          </div>

          <CommonStyles.StepUI current={Number(step) - 1} items={itemSteps} />

          <div className="absolute right-0 bottom-0">
            <CommonIcons.IconHeart width={150} height={150} />
          </div>
        </div>
      </div>

      <div className={`flex ${responseUI.screen}  py-10 ${responseUI.padding}`}>
        <div className="lg:basis-1/2 w-full">{renderStep()}</div>

        {/* right */}
        {step !== "4" && (
          <div className="basis-1/2 hidden lg:block">
            <div>
              <img className="rounded-e-lg h-fit" src={CommonImages.imageBooking} alt={CommonImages.imageBooking} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
