import { articles } from "@base/constants/mocks";
import CardOutstanding from "@components/CommonStyles/CardOutstanding";

export default function Outstanding() {
  //! state
  //! function
  //! render
  const renderOutstanding = () => {
    return articles.map((item, index) => {
      return <CardOutstanding key={index} />;
    });
  };
  return <div className="flex flex-col gap-4">{renderOutstanding()}</div>;
}
