import "./styles/App.css";
import { useEffect, useState } from "react";
import SettingProvider from "./providers/SettingProvider";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store/store";
import { setAlertMessage, setAuth } from "./store/reducers/appCommonSlice";
import useGetUserInfo from "./modules/user/account/hooks/useGetUserInfo";
import { getAuthLocal } from "./helpers/common";
import { LocalKey } from "./constants/LocalKey";

function App() {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const { handleGetUserInfo } = useGetUserInfo();

  const alertMessage = useSelector((state: RootState) => state.appCommonSlice.alertMessage);
  const authLogin = useSelector((state: RootState) => state.appCommonSlice.auth);

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // init
    setIsInitialized(true);
    // get user info and save data to redux
    const authLoginLocal = getAuthLocal();

    if (authLoginLocal?.access_token) {
      dispatch(setAuth(authLoginLocal));
      handleGetUserInfo();
    }

    // destroy component
    return () => {
      setIsInitialized(false);
      dispatch(setAlertMessage({}));
    };
  }, []);

  useEffect(() => {
    if (isInitialized && alertMessage && alertMessage.type) {
      api[alertMessage.type]({
        message: alertMessage.message,
        description: alertMessage.description,
      });
    }
  }, [alertMessage]);

  useEffect(() => {
    if (authLogin?.access_token) {
      handleGetUserInfo();
    }
  }, [authLogin]);

  return (
    <>
      {contextHolder}
      <SettingProvider></SettingProvider>
    </>
  );
}

export default App;
