import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import useGetDoctors from "@base/modules/doctor/hooks/useGetDoctors";
import IFilterDcotor from "@base/modules/doctor/interface/filterDoctor.interafce";
import { ISpecialization } from "@base/modules/specialization/interface/specialization.interface";
import { baseRoutes } from "@base/routes/baseRoutes";
import CommonStyles from "@components/CommonStyles";
import CardDoctorSpecial from "@components/CommonStyles/CardDoctorSpecial";
import { Divider } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function TabDoctorSpecialList() {
  //! state
  const [searchParams] = useSearchParams();
  const specialization_code = searchParams.get("specialization_code");
  const navigate = useNavigate();
  const [filters, setFilters] = useState<IFilterDcotor>({
    page: 0,
    size: 10,
    doctor_name: "",
    specialization_code: specialization_code,
    online: null,
    state_code: null,
  });
  const [textSearch, setTextSearch] = useState("");
  const { data: dataDoctor, isLoading: loadingDoctor, refetch: refetchDoctor } = useGetDoctors(filters, false);

  //! function
  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextSearch(value);
    onSearchDebounce(event.target.value.trim());
  };

  const onSearchDebounce = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev) => ({ ...prev, doctor_name: value }));
      }, 1000),
    [],
  );

  const handleChangePage = (value: any) => {
    setFilters((prev) => ({ ...prev, page: value - 1 }));
  };

  useEffect(() => {
    refetchDoctor();
  }, [filters]);
  //! render
  const renderDoctor = () => {
    return dataDoctor?.data.content_page?.map((item, index) => {
      const handledetailDoctor = () => {
        navigate(`${baseRoutes.doctor}/${item.doctor_code}`);
      };
      return (
        <React.Fragment key={index}>
          <CardDoctorSpecial root="specialist" doctor={item} handleDetail={handledetailDoctor} />
          <Divider />
        </React.Fragment>
      );
    });
  };
  return (
    <div className="p-4 mt-5 rounded-lg bg-common-white">
      <div className="flex items-center gap-4 ">
        <div className="grow">
          <CommonStyles.InputUI
            value={textSearch}
            onChange={changeSearch}
            prefix={<CommonIcons.SearchOutlined />}
            placeholder="Nhập tên chuyên khoa cần tìm..."
            variant="filled"
          />
        </div>
        <CommonStyles.ButtonUI icon={<CommonIcons.SearchOutlined />} className=" h-12 !px-6 hover:!bg-common-orange100" />
      </div>

      <div className="mt-6">
        {renderDoctor()}

        <div className="flex justify-end mt-6">
          <CommonStyles.PaginationUI
            current={filters.page + 1}
            pageSize={filters.size}
            onChange={handleChangePage}
            total={dataDoctor?.data.total_records}
          />
        </div>
      </div>
    </div>
  );
}
