import React, { useEffect, useState } from "react";
import { ISpecialPackage, ISpecialPackageDetail } from "@base/modules/patientSpecialPackage/patientSpecialPackage.interface";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import ServicePackageCard from "./ServicePackageCard";
import patientSpecialPackageService from "@base/modules/patientSpecialPackage/patientSpecialPackageService";
import CommonStyles from "@components/CommonStyles";
import { EAppointmentType, ESpecialPackageStatus, ETagSpecialPackageDetailType, ETypeServicePackage } from "@base/enums";
import dayjs from "dayjs";
import themeColor from "@base/themes/colorTheme";
import CommonIcons from "@base/assets/icons";
import TagSpecialPackageDetailImplementStatus from "@components/CommonStyles/TagSpecialPackageDetailImplementStatus";
import TagSpecialPackageDetailType from "@components/CommonStyles/TagSpecialPackageDetailType";
import { baseRoutes } from "@base/routes/baseRoutes";
import useToggle from "@base/hooks/useToggle";
import CardTransferInfo from "@components/CommonStyles/CardTransferInfo";

export default function ServicePackageDetail() {
  //! state
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { key } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [specialPackage, setSpecialPackage] = useState<ISpecialPackage>({});
  const { open: openConfirmTransfer, shouldRender: shouldRenderConfirmTransfer, toggle: toggleConfirmTransfer } = useToggle();

  //! function
  useEffect(() => {
    getSpecialPackage();
  }, []);

  const handleBookingPackage = (record: ISpecialPackageDetail) => {
    navigate({
      pathname: baseRoutes.bookingAppointment,
      search: createSearchParams({
        site_code: String(specialPackage.site?.code),
        package_name: String(specialPackage.package_name),
        expected_date: String(
          (record.patient_special_package_detail_implements && record.patient_special_package_detail_implements[0].expected_date) ??
            dayjs().format("YYYY-MM-DD"),
        ),
        symptoms: String(record.item_name),
        package_key: String(specialPackage.key),
        id_implement: String(record.patient_special_package_detail_implements && record.patient_special_package_detail_implements[0].id),
        type: String(record.type === ETypeServicePackage.OFFLINE_SERVICE) ? EAppointmentType.OFFLINE : EAppointmentType.ONLINE,
      }).toString(),
    });
  };

  const getSpecialPackage = async () => {
    if (!key) {
      return;
    }

    try {
      if (!isLoading) {
        setIsLoading(true);
      }
      const resp = await patientSpecialPackageService.getDetail(key);
      setSpecialPackage(resp.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  //! render
  const items = specialPackage.patient_special_package_details?.map((spDetail: ISpecialPackageDetail, idx: number) => {
    const status = spDetail.patient_special_package_detail_implements?.length
      ? spDetail.patient_special_package_detail_implements[0].status
      : undefined;

    const implemented_date = spDetail.patient_special_package_detail_implements?.length
      ? spDetail.patient_special_package_detail_implements[0].implemented_date
      : undefined;

    const expected_date = spDetail.patient_special_package_detail_implements?.length
      ? spDetail.patient_special_package_detail_implements[0].expected_date
      : undefined;

    const appt_key = spDetail.patient_special_package_detail_implements?.length
      ? spDetail.patient_special_package_detail_implements[0].appt_key
      : undefined;

    return {
      dot: (
        <CommonStyles.TimelineDotUI
          className="p-2 w-8 h-8"
          bgColor={status === ESpecialPackageStatus.COMPLETED ? themeColor.green100 : themeColor.blue500}
          borderColor={status === ESpecialPackageStatus.COMPLETED ? themeColor.green500 : themeColor.blue500}
          textColor={status === ESpecialPackageStatus.COMPLETED ? themeColor.green500 : themeColor.white}
          content={status === ESpecialPackageStatus.COMPLETED ? <CommonIcons.CheckOutlined /> : idx + 1}
        />
      ),
      children: (
        <div className="w-full flex items-start gap-8">
          <div className="flex-none w-[200px] max-md:hidden">
            <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext text-xs font-medium font-['Inter'] !m-0 cursor-pointer">
              <span title={spDetail.item_name}>{spDetail.item_name}</span>
            </CommonStyles.TypographyUI>

            <TagSpecialPackageDetailImplementStatus status={status as ESpecialPackageStatus} />
          </div>

          <div className="grow bg-common-background rounded-lg p-3 flex justify-between items-center max-sm:flex-col max-sm:justify-start max-sm:items-start gap-2">
            <div>
              <div className="flex flex-col gap-2 mb-1 md:hidden">
                <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext text-sm font-semibold font-['Inter'] !m-0 cursor-pointer">
                  <span title={spDetail.item_name}>{spDetail.item_name}</span>
                </CommonStyles.TypographyUI>

                <TagSpecialPackageDetailImplementStatus status={status as ESpecialPackageStatus} />
              </div>

              <div className="flex items-center gap-2 mb-1">
                <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext text-xs font-normal font-['Inter'] !m-0">
                  {status === ESpecialPackageStatus.COMPLETED || (status === ESpecialPackageStatus.PENDING && appt_key)
                    ? "Ngày thực hiện:"
                    : "Ngày dự kiến"}
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext text-sm font-semibold font-['Inter'] !m-0">
                  {status === ESpecialPackageStatus.COMPLETED && implemented_date ? dayjs(implemented_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}
                  {status === ESpecialPackageStatus.PENDING && expected_date ? dayjs(expected_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex items-center gap-2">
                <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext text-xs font-normal font-['Inter'] !m-0">
                  Hình thức
                </CommonStyles.TypographyUI>
                <TagSpecialPackageDetailType type={spDetail.type as ETagSpecialPackageDetailType} />
              </div>
            </div>

            {specialPackage.status === ESpecialPackageStatus.IN_PROGRESS && status === ESpecialPackageStatus.PENDING && !appt_key ? (
              <div className="max-sm:w-full">
                <CommonStyles.ButtonUI
                  className="float-right"
                  onClick={() => {
                    handleBookingPackage(spDetail);
                  }}
                >
                  {t("user.servicePackage.btnBooking")}
                </CommonStyles.ButtonUI>
              </div>
            ) : null}

            {status === ESpecialPackageStatus.PENDING && appt_key ? (
              <div className="max-sm:w-full">
                <CommonStyles.ButtonUI
                  className="float-right"
                  onClick={() => navigate(baseRoutes.userAppointmentDetail.replace(":key", appt_key))}
                  ghost
                >
                  {t("user.servicePackage.btnApptDetail")}
                </CommonStyles.ButtonUI>
              </div>
            ) : null}
          </div>
        </div>
      ),
    };
  });

  if (isLoading) {
    return <CommonStyles.LoadingUI />;
  }

  return (
    <div className="p-5">
      <div className="flex flex-col">
        <div className="text-common-cyan500 text-base font-bold font-['Inter']">{t("user.servicePackage.servicePackageDetail")}</div>

        <div></div>
      </div>

      <Divider />

      <div className="w-full">
        <ServicePackageCard specialPackage={specialPackage} />

        {specialPackage.status === ESpecialPackageStatus.UNPAID ? <CardTransferInfo showDetail={toggleConfirmTransfer} /> : null}

        {specialPackage.status === ESpecialPackageStatus.CANCELLED && specialPackage.cancelReason ? (
          <div className="w-full mt-3 flex items-center gap-5">
            <CommonStyles.TypographyUI use="paragraph" className="text-[#000000] text-base font-medium font-['Inter'] !mb-0">
              Lý do hủy:
            </CommonStyles.TypographyUI>

            <CommonStyles.TypographyUI use="paragraph" className="text-common-negative text-base font-medium font-['Inter'] !mb-0">
              {specialPackage.cancelReason}
            </CommonStyles.TypographyUI>
          </div>
        ) : null}

        <div className="w-full my-3">
          <CommonStyles.TypographyUI use="paragraph" className="text-[#000000] text-base font-medium font-['Inter']">
            Hành trình khám
          </CommonStyles.TypographyUI>

          {specialPackage.patient_special_package_details?.length ? (
            <div className="pt-4 max-h-[610px] overflow-y-auto">
              <CommonStyles.TimelineUI items={items} />
            </div>
          ) : null}
        </div>
      </div>

      {shouldRenderConfirmTransfer && (
        <CommonStyles.TransferInformationDialog
          transferContent={
            (specialPackage?.package_name ?? "[Gói dịch vụ]") +
            " - " +
            (specialPackage?.patient_record?.patient_name ?? "[Hồ sơ]") +
            " - " +
            (specialPackage?.patient_record?.patient_phone_number ?? "[SĐT]")
          }
          toggle={toggleConfirmTransfer}
          open={openConfirmTransfer}
        />
      )}
    </div>
  );
}
