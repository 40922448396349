export const apiAuthorize = `${process.env.REACT_APP_API_AUTHORIZE_BASIC}`;

export const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;

export const apiService = {
  auth: {
    login: `${apiUrl}/auth/oauth/token`,

    user: {
      register: `${apiUrl}/auth/user/register`,
      logout: `${apiUrl}/auth/user/logout`,
      checkOTP: `${apiUrl}/auth/user/check-otp`,
      getOTP: `${apiUrl}/auth/user/get-otp`,
      updatePassword: `${apiUrl}/auth/user/update-password`,
      getAccountSetting: `${apiUrl}/auth/user/update-profiles`,
      updateAccountSetting: `${apiUrl}/auth/user/update-profiles`,
      getUserInfo: `${apiUrl}/auth/user/get-user-info`,
      uploadAvatar: `${apiUrl}/auth/user/upload-avatar`,
    },

    site: {
      getList: `${apiUrl}/auth/site/get-list`,
      getByCode: `${apiUrl}/auth/site/get-by-code`,
      getById: `${apiUrl}/auth/site/get-by-id`,
    },

    state: {
      getList: `${apiUrl}/auth/state/list`,
    },
  },

  patient: {
    record: {
      create: `${apiUrl}/patient/patient-record/`,
      update: `${apiUrl}/patient/patient-record`,
      delete: `${apiUrl}/patient/patient-record/delete`,
      seDefault: `${apiUrl}/patient/patient-record/set-default`,
      getById: `${apiUrl}/patient/patient-record`,
      getByUserKey: `${apiUrl}/patient/patient-record/by-user-key`,
      getByCode: `${apiUrl}/patient/patient-record/by-code`,
      syncClinicRecord: `${apiUrl}/patient/patient-record/sync-clinic-record`,
    },

    order: {
      create: `${apiUrl}/patient/order/`,
      orderCode: `${apiUrl}/patient/order/`,
      myOrders: `${apiUrl}/patient/order/my-orders`,
      orderTransCode: `${apiUrl}/patient/order/get-by-order-trans-code`,
      orderByRefCode: `${apiUrl}/patient/order/get-by-ref-code`,
    },

    patientSpecialPackage: {
      calculateFee: `${apiUrl}/patient/patient-special-package/calculate-fee`,
      buy: `${apiUrl}/patient/patient-special-package/buy`,
      findAll: `${apiUrl}/patient/patient-special-package/find-all-by-patient-record-code`,
      getDetails: `${apiUrl}/patient/patient-special-package/get-details`,
    },

    reAppointment: {
      create: `${apiUrl}/patient/re-appointment/`,
      getMyReAppointment: `${apiUrl}/patient/re-appointment/my-re-appointment`,
      getById: `${apiUrl}/patient/re-appointment`,
    },

    drugOrder: {
      create: `${apiUrl}/patient/drug-order/`,
      getAllByPatientRecordCode: `${apiUrl}/patient/drug-order/get-all-by-patient-record-code`,
      uploadImage: `${apiUrl}/patient/drug-order/upload-image-drug-order`,
      getByCode: `${apiUrl}/patient/drug-order`,
    },
  },

  doctor: {
    getList: `${apiUrl}/doctor/doctor/list-doctor`,
    getByCodeLess: `${apiUrl}/doctor/doctor/get-by-code-less`,

    specialization: {
      getList: `${apiUrl}/doctor/specialization/get-list`,
      getById: `${apiUrl}/doctor/specialization`,
      all: `${apiUrl}/doctor/specialization/all`,
    },

    drService: {
      getList: `${apiUrl}/doctor/dr-service/get-list-show`,
      getById: `${apiUrl}/doctor/dr-service/get-by-id`,
    },

    timeTable: {
      getAllByDoctorCodeAndDate: `${apiUrl}/doctor/time-table/get-all-by-doctor-code-and-date`,
    },

    appointment: {
      create: `${apiUrl}/doctor/appointment/`,
      getMyAppointment: `${apiUrl}/doctor/appointment/my-appointment`,
      getById: `${apiUrl}/doctor/appointment`,
      getByKey: `${apiUrl}/doctor/appointment/get-by-key`,
      setCancelled: `${apiUrl}/doctor/appointment/set-cancelled`,
      rating: `${apiUrl}/doctor/appointment-rating/`,
      createFromPackage: `${apiUrl}/doctor/appointment/create-from-package/`,
      calculateFee: `${apiUrl}/doctor/appointment/calculate-fee`,
    },

    symptom: {
      getList: `${apiUrl}/doctor/symptom-setting/get-list`,
    },
  },

  utils: {
    administrative: {
      getAllProvince: `${apiUrl}/utils/administrative/all-provinces`,
      getAllDistrictByProvinceCode: `${apiUrl}/utils/administrative/all-district-by-province-code`,
    },

    promotion: {
      listPublic: `${apiUrl}/utils/promotion/list-public`,
      listPublicOnline: `${apiUrl}/utils/promotion/list-public-online`,
      apply: `${apiUrl}/utils/promotion/apply`,
      getByType: `${apiUrl}/utils/promotion/get-by-type`,
    },

    siteSpecialPackage: {
      getAllForApp: `${apiUrl}/utils/site-special-package/all-for-app`,
      getOne: `${apiUrl}/utils/site-special-package/get-by-site-code-and-package-code`,
    },

    specialCategory: {
      getListShow: `${apiUrl}/utils/special-category/list`,
    },

    ratingComment: {
      getList: `${apiUrl}/utils/rating-comment/list-rating-comment`,
    },

    common: {
      getList: `${apiUrl}/utils/common/list`,
    },

    banner: {
      list: `${apiUrl}/utils/banner/list`,
      addView: `${apiUrl}/utils/banner/add-view`,
    },

    home: {
      listForWeb: `${apiUrl}/utils/home/list-for-web`,
    },

    allPageSearch: {
      getTopKeyWords: `${apiUrl}/utils/all-page-search/get-top-keywords`,
      search: `${apiUrl}/utils/all-page-search/search`,
    },

    collaboration: {
      getAll: `${apiUrl}/utils/collaboration/all`,
      register: `${apiUrl}/utils/collaboration/register`,
      update: `${apiUrl}/utils/collaboration/update`,
      testCaptcha: `${apiUrl}/utils/collaboration/test-captcha`,
    },
  },
};
