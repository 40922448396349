import React from "react";
import { Divider, Modal, QRCode } from "antd";
import ButtonUI from "../Button";
import TypographyUI from "../Typography";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@base/store/store";
import CommonIcons from "@base/assets/icons";
import { useTranslation } from "react-i18next";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";

export default function TransferInformationDialog({
  open = false,
  toggle,
  transferContent = "",
}: {
  open: boolean;
  toggle: () => void;
  transferContent?: string;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dataCommon = useSelector((state: RootState) => state.appCommonSlice.dataCommon);

  const BANK_NAME = dataCommon?.data.commons.find((item) => item.key === "BANK_NAME")?.description || "-";
  const BANK_NUMBER = dataCommon?.data.commons.find((item) => item.key === "BANK_NUMBER")?.description || "-";
  const BANK_RECEIVER = dataCommon?.data.commons.find((item) => item.key === "BANK_RECEIVER")?.description || "-";
  const BANK_RECEIVER_QR = dataCommon?.data.commons.find((item) => item.key === "BANK_RECEIVER_QR")?.article_link || "-";

  const onCopyRefCode = (value?: string) => {
    navigator.clipboard.writeText(value ?? "");

    dispatch(setAlertMessage({ type: "success", message: t("Notification"), description: t("Copied") + "!" }));
  };

  return (
    <Modal
      width={993}
      open={open}
      centered
      closable
      onCancel={toggle}
      title={
        <div className="w-full flex justify-center">
          <TypographyUI use="paragraph" className="!text-lg lg:!text-2xl font-medium text-common-maintext">
            Chuyển khoản ngân hàng
          </TypographyUI>
        </div>
      }
      footer={
        <div className="w-full flex justify-center">
          <ButtonUI className="h-12 text-base font-medium" onClick={toggle}>
            Đóng
          </ButtonUI>
        </div>
      }
    >
      <div className="flex gap-11 max-lg:flex-col">
        <div className="flex-none w-fit max-lg:w-full max-lg:flex max-lg:justify-center">
          <img src={BANK_RECEIVER_QR} className="w-[160px] h-[160px] md:w-[220px] md:h-[220px]" />
        </div>

        <div className="grow flex flex-col gap-3">
          <div className="flex gap-2 max-md:flex-col">
            <TypographyUI use="text" className="!text-sm lg:!text-base !font-normal !text-common-subtext !mb-0 inline !w-[200px]">
              Ngân hàng:
            </TypographyUI>
            <TypographyUI use="paragraph" className="!text-sm lg:!text-base !font-medium !text-common-maintext !mb-0">
              {BANK_NAME}
            </TypographyUI>
          </div>
          <div className="flex gap-2 max-md:flex-col">
            <TypographyUI use="text" className="!text-sm lg:!text-base !font-normal !text-common-subtext !mb-0 inline !w-[200px]">
              Số tài khoản:
            </TypographyUI>
            <TypographyUI use="paragraph" className="!text-sm lg:!text-base !font-medium !text-common-maintext !mb-0">
              {BANK_NUMBER}

              {BANK_NUMBER ? (
                <div
                  className="flex items-center gap-2 text-sm font-medium font-['Inter'] text-common-orange cursor-pointer"
                  onClick={() => onCopyRefCode(BANK_NUMBER)}
                >
                  <CommonIcons.IconCopy /> {t("copy")}
                </div>
              ) : null}
            </TypographyUI>
          </div>
          <div className="flex gap-2 max-md:flex-col">
            <TypographyUI use="text" className="!text-sm lg:!text-base !font-normal !text-common-subtext !mb-0 inline !w-[200px]">
              Chủ tài khoản:
            </TypographyUI>
            <TypographyUI use="paragraph" className="!text-sm lg:!text-base !font-medium !text-common-maintext !mb-0">
              {BANK_RECEIVER}
            </TypographyUI>
          </div>
          <div className="flex gap-2 max-md:flex-col">
            <TypographyUI use="text" className="!text-sm lg:!text-base !font-normal !text-common-subtext !mb-0 inline !w-[200px]">
              Nội dung chuyển khoản:
            </TypographyUI>
            <TypographyUI use="paragraph" className="!text-sm lg:!text-base !font-medium !text-common-maintext !mb-0">
              {transferContent}

              {transferContent ? (
                <div
                  className="flex items-center gap-2 text-sm font-medium font-['Inter'] text-common-orange cursor-pointer"
                  onClick={() => onCopyRefCode(transferContent)}
                >
                  <CommonIcons.IconCopy /> {t("copy")}
                </div>
              ) : null}
            </TypographyUI>
          </div>
        </div>
      </div>

      <Divider className="my-4" />

      <div className="">
        <TypographyUI use="paragraph" className="!text-sm lg:!text-base font-medium text-common-maintext !mb-2">
          Lưu ý
        </TypographyUI>

        <ul className="list-disc ml-6">
          <li>
            <TypographyUI use="paragraph" className="!text-sm lg:!text-base font-normal text-common-subtext !mb-1">
              Hệ thống sẽ tự động xử lý giao dịch của bạn sau khi chuyển khoản
            </TypographyUI>
          </li>
          <li>
            <TypographyUI use="paragraph" className="!text-sm lg:!text-base font-normal text-common-subtext !mb-1">
              Trong trường hợp sau 5 phút không được xử lý, vui lòng liên hệ hotline: <span className="underline text-common-blue500">19001818</span>{" "}
              để được hỗ trợ
            </TypographyUI>
          </li>
        </ul>
      </div>
    </Modal>
  );
}
