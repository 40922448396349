import { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import IFilterAppointment from "../appointment/filterAppointment.interface";
import { IAppointmentPage } from "../appointment/appointment.interface";
import reAppointmentService from "./reAppointmentService";

const useGetMyReAppointment = (filters: IFilterAppointment, enabled = true) => {
  const [data, setData] = useState<IAppointmentPage>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return reAppointmentService.getMyReAppointments(filters);
  }, [filters]);

  const transformResponse = useCallback((response: AxiosResponse<IAppointmentPage>) => {
    if (response) {
      setData(response.data);
    }
  }, []);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { reAppointmentData: data, reAppointmentError: error, isLoadingReAppointment: isLoading, refetchReAppointment: refetch };
};

export default useGetMyReAppointment;
