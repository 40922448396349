import { apiService } from "@base/configs";
import httpService from "@base/services/httpService";
import { IAppointment, IAppointmentPage } from "../appointment/appointment.interface";
import IFilterAppointment from "../appointment/filterAppointment.interface";

class ReAppointmentService {
  // create
  createReAppointment(payload: IAppointment, query?: { lang: string }) {
    return httpService.post(`${apiService.patient.reAppointment.create}`, payload, {
      params: query,
    });
  }

  // get my re-appointments
  getMyReAppointments(params?: IFilterAppointment) {
    return httpService.get<IAppointmentPage>(`${apiService.patient.reAppointment.getMyReAppointment}`, {
      params: params,
    });
  }

  // get by id
  getById(id: string | number) {
    return httpService.get<IAppointment>(`${apiService.patient.reAppointment.getById}/${id}`);
  }
}

export default new ReAppointmentService();
