import { Button, Divider, Space, Typography } from "antd";
import themeColor from "../themes/colorTheme";
import { Input } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CommonImages from "@base/assets/images";
import { NavLink, useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import useGetListCommon from "@base/modules/common/hooks/useGetListCommon";
import { linkAppStore, linkGGPlay } from "@base/constants";
import { useSelector } from "react-redux";
import { RootState } from "@base/store/store";
import CommonIcons from "@base/assets/icons";
import { backToTop } from "@base/helpers/common";

const { Link } = Typography;

const RefLink = ({ text = "", href = "" }: { text: string; href: string }) => {
  return (
    <Link
      style={{ color: themeColor.subtext }}
      className="text-sm font-normal font-['Open_Sans'] flex items-center gap-1"
      href={href}
      target="_blank"
    >
      <img src={CommonImages.ellipseFilled} alt="" />
      {text}
    </Link>
  );
};

const FooterLayout = () => {
  //! state
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dataCommon = useSelector((state: RootState) => state.appCommonSlice.dataCommon);

  const hotline = dataCommon?.data.commons.find((item) => item.key === "APP_HOTLINE");
  const mail = dataCommon?.data.commons.find((item) => item.key === "APP_EMAIL");
  const faq = dataCommon?.data.commons.find((item) => item.key === "WEB_FAQ");
  const about = dataCommon?.data.commons.find((item) => item.key === "WEB_GIOI_THIEU");
  const app_fabook = dataCommon?.data.commons.find((item) => item.key === "APP_FACEBOOK");
  const terms = dataCommon?.data.commons.find((item) => item.key === "WEB_DIEU_KHOAN");
  const terms_of_payment = dataCommon?.data.commons.find((item) => item.key === "WEB_DIEU_KHOAN_THANH_TOAN");
  const app_youtube = dataCommon?.data.commons.find((item) => item.key === "APP_YOUTUBE");
  const ISO9001 = dataCommon?.data.commons.find((item) => item.key === "LINK_ISO_9001");
  const ISO27001 = dataCommon?.data.commons.find((item) => item.key === "LINK_ISO_27001");

  //! function
  const handleLinkToPage = (url: string) => {
    navigate(url);
  };
  //! render
  return (
    // md:mb-48 lg:mb-96 xl:mb-0
    <div className="grid grid-flow-row bg-common-white py-20">
      <div className="w-full flex flex-col lg:flex-row gap-7 lg:gap-10">
        <div className="basis-1/5">
          <div
            className="mb-[30px]"
            onClick={() => {
              handleLinkToPage(`${baseRoutes.home}`);
            }}
          >
            <img src={CommonImages.pageLayoutLogo} alt="" />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-2 items-center">
              <div className="">
                <CommonIcons.IconPhone />
              </div>
              <div>
                <Link className="text-sm !text-common-maintext font-normal font-['Inter']" href={`tel:${hotline?.article_link}`}>
                  {hotline?.article_link}
                </Link>
              </div>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <div className="">
                <CommonIcons.IconEmail />
              </div>
              <div className="text-sm text-common-maintext font-normal font-['Inter']">
                <Link className="text-sm !text-common-maintext font-normal font-['Inter']" href={`mailto:${mail?.article_link}`}>
                  {mail?.article_link}
                </Link>
              </div>
            </div>

            <div>
              <div className="flex flex-row gap-2 items-center">
                <div className="">
                  <CommonIcons.IconLocationFooter />
                </div>
                <div className="text-sm text-common-maintext font-normal font-['Inter']">Số 15 Trần Xuân Soạn, Q. Hai Bà Trưng, Hà Nội</div>
              </div>
              <div className="pl-[26px]">
                <Divider style={{ marginTop: 8, marginBottom: 8 }} />

                <div className="text-sm text-common-maintext font-normal font-['Inter']">134/1 Cách Mạng Tháng Tám, P.10, Q.3, Tp. Hồ Chí Minh</div>
              </div>
            </div>
          </div>
        </div>

        <div className="basis-3/5 flex flex-col lg:flex-row lg:flex-nowrap lg:justify-around gap-5 lg:gap-10 ">
          <div>
            <div className="text-base font-medium font-['Inter'] mb-5 lg:mb-[30px]">{t("Footer.Introduce")}</div>

            <div className="flex flex-col gap-4">
              <NavLink target="_blank" to={String(about?.article_link)} className="text-sm text-common-maintext font-normal font-['Inter']">
                {t("Footer.About 365 Medihome")}
              </NavLink>
              <NavLink target="_blank" to={String(faq?.article_link)} className="text-sm text-common-maintext font-normal font-['Inter']">
                {t("Footer.FAQ")}
              </NavLink>
            </div>
          </div>

          <div>
            <div className="text-base font-medium font-['Inter']  mb-5 lg:mb-[30px]">{t("Footer.Booking")}</div>

            <div className="flex flex-col gap-4">
              <div
                className="flex flex-row items-center cursor-pointer group"
                onClick={() => {
                  handleLinkToPage(`${baseRoutes.orderFacility}`);
                  backToTop("smooth");
                }}
              >
                <div>
                  <img src={CommonImages.arrowSmRight} alt="" />
                </div>
                <div className="group-hover:text-common-mainColor text-sm text-common-maintext font-normal font-['Inter']">
                  {t("Footer.Direct examination at a medical facility")}
                </div>
              </div>
              <div
                className="flex flex-row items-center cursor-pointer group"
                onClick={() => {
                  handleLinkToPage(`${baseRoutes.bookingOnline}`);
                  backToTop("smooth");
                }}
              >
                <div>
                  <img src={CommonImages.arrowSmRight} alt="" />
                </div>
                <div className="group-hover:text-common-mainColor text-sm text-common-maintext font-normal font-['Inter']">
                  {t("Footer.Online counseling")}
                </div>
              </div>
              <div
                className="flex flex-row items-center cursor-pointer group"
                onClick={() => {
                  handleLinkToPage(`${baseRoutes.doctor}`);
                  backToTop("smooth");
                }}
              >
                <div>
                  <img src={CommonImages.arrowSmRight} alt="" />
                </div>
                <div className="group-hover:text-common-mainColor  text-sm text-common-maintext font-normal font-['Inter']">
                  {t("Footer.Make a doctor's appointment")}
                </div>
              </div>
              <div
                className="flex flex-row items-center cursor-pointer group"
                onClick={() => {
                  handleLinkToPage(`${baseRoutes.bookingPackage}`);
                  backToTop("smooth");
                }}
              >
                <div>
                  <img src={CommonImages.arrowSmRight} alt="" />
                </div>
                <div className="group-hover:text-common-mainColor text-sm text-common-maintext font-normal font-['Inter']">
                  {t("Footer.Schedule an examination package")}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="text-base font-medium font-['Inter'] mb-5 lg:mb-[30px]">{t("Footer.ConnectUs")}</div>

            <div className="flex gap-4">
              <NavLink to={`https://wwww.facebook.com/${String(app_fabook?.article_link)}`} target="_blank">
                <img src={CommonImages.FB} />
              </NavLink>

              <NavLink to={`https://www.youtube.com/${String(app_youtube?.article_link)}`} target="_blank">
                <img src={CommonImages.YT} />
              </NavLink>
            </div>
          </div>
        </div>

        <div className="basis-1/5">
          <div className="text-base font-medium font-['Inter'] mb-4 lg:mb-[30px]">{t("Footer.Download App")}</div>

          <div className="flex flex-col gap-[10px]">
            <div className="text-sm text-common-maintext font-normal font-['Inter']">{t("Footer.Download the app to receive more offers")}</div>
            <NavLink to={linkGGPlay} target="_blank">
              <img src={CommonImages.GGPlay} />
            </NavLink>
            <NavLink to={linkAppStore} target="_blank">
              <img src={CommonImages.AppStore} />
            </NavLink>
          </div>
        </div>
      </div>

      <Divider />

      <div className="w-full flex flex-wrap md:flex-nowrap gap-4 justify-between items-center">
        <div>
          <div className="text-base font-semibold text-common-maintext font-['Inter']">Công ty Cổ phần Medihome</div>
          <div className="text-xs font-normal text-common-maintext font-['Inter']">GPDKKD số 0106462477 do Sở KH và ĐT TP.Hà Nội</div>
        </div>

        <div>
          <div className="flex gap-3 items-center">
            <div>
              <img src={CommonImages.RegisterBCT} alt={CommonImages.RegisterBCT} />
            </div>
            <div>
              <img src={CommonImages.NotifyBCT} alt={CommonImages.NotifyBCT} />
            </div>
            <Link href={`${ISO27001?.article_link}`} target="_blank">
              <img src={CommonImages.ISO27001} alt={CommonImages.ISO27001} />
            </Link>
            <Link href={`${ISO9001?.article_link}`} target="_blank">
              <img src={CommonImages.ISO9001} alt={CommonImages.ISO9001} />
            </Link>
          </div>
        </div>
      </div>

      <Divider />

      <Space wrap size={32}>
        <RefLink text={t("Footer.General policies & regulations")} href={String(terms?.article_link)} />
        <RefLink text={t("Footer.Regulations & payment methods")} href={String(terms_of_payment?.article_link)} />
        {/* <RefLink text={t("Footer.Personal information security policy")} href="#" /> */}
        {/* <RefLink text={t("Footer.Dispute and complaint resolution mechanism")} href="#" /> */}
      </Space>
    </div>
  );
};

export default FooterLayout;
