import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import { articles } from "@base/constants/mocks";
import MedicalManualLayout from "@base/layouts/MedicalManual/MedicalManualLayout";
import themeColor from "@base/themes/colorTheme";
import CommonStyles from "@components/CommonStyles";
import CardArticle from "@components/CommonStyles/CardArticle";
import Comment from "./Comment";
import { baseRoutes } from "@base/routes/baseRoutes";

export default function MedicalInfoDetail() {
  //! state
  const tags = ["Y tế", "Sức khỏe", "Trẻ em", "Sơ cứu"];
  const breadcrumItem = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      href: baseRoutes.medicalInfo,
      title: "Tin tức",
    },
    {
      title: "Bài viết chi tiết",
    },
  ];
  //! function
  //! render
  const header = () => {
    return (
      <div className="max-w-[800px] flex flex-col h-full justify-between">
        {/* <CommonStyles.BreadcrumbUI className="py-5" items={breadcrumItem} /> */}

        <div>
          <CommonStyles.TypographyUI use="title" className="font-semibold text-common-maintext" level={2}>
            Trẻ bị ngộ độc thức ăn nên làm gì? 4 nguyên tắc cần nắm rõ
          </CommonStyles.TypographyUI>
          <div className="flex gap-6">
            <div className="flex gap-2 items-center">
              <CommonIcons.IconPersonEdit />
              <CommonStyles.TypographyUI className="text-base">Admin</CommonStyles.TypographyUI>
            </div>

            <div className="flex gap-2 items-center">
              <CommonIcons.IconCalendarFill fill={themeColor.orange} />
              <CommonStyles.TypographyUI className="text-base">15/05/2024</CommonStyles.TypographyUI>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderFooter = () => {
    return (
      <div className="flex justify-between border-t border-common-divider pt-6">
        <div className="flex items-center gap-4">
          <CommonStyles.ButtonUI
            type="default"
            className="bg-common-blue100 rounded-3xl border-none text-common-blue600"
            icon={<CommonIcons.ShareAltOutlined />}
          >
            Chia sẻ
          </CommonStyles.ButtonUI>

          <CommonStyles.ButtonUI
            type="default"
            className="bg-common-blue100 rounded-3xl border-none text-common-blue600"
            icon={<CommonIcons.HeartOutlineAntd />}
          >
            Yêu thích
          </CommonStyles.ButtonUI>
        </div>
        <div className="flex gap-3 flex-wrap">
          {tags.map((tag, index) => {
            return <CommonStyles.TagUI className="px-4 py-2 opacity-80 m-0 text-base font-medium" tagName={tag} key={index} />;
          })}
        </div>
      </div>
    );
  };
  const renderArticleOther = () => {
    return articles.map((item, index) => {
      return <CardArticle cardColor={themeColor.white} content={item.content} date={item.date} title={item.title} key={index} />;
    });
  };
  return (
    <MedicalManualLayout
      Header={header()}
      Content={
        <div>
          <div className="bg-common-white p-4 rounded-lg flex flex-col gap-8">
            <div>
              <CommonStyles.TypographyUI use="title" className="!text-lg font-semibold">
                1. Heading
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim book. It has survived not
                only five centuries, but also the leap into electronic...Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specim book. It has survived not only five centuries, but also the leap into electronic...
              </CommonStyles.TypographyUI>
              <div className="my-4">
                <img className="w-full rounded-lg" src={CommonImages.DrafMedicalDetail} alt={CommonImages.DrafMedicalDetail} />
              </div>
              <div className="border-l-2 border-common-maintext pl-2 bg-common-background">
                <CommonStyles.TypographyUI className="text-common-maintext italic text-base">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                  ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim book.
                </CommonStyles.TypographyUI>
              </div>
            </div>

            <div>
              <CommonStyles.TypographyUI use="title" className="!text-lg font-semibold">
                2. Heading
              </CommonStyles.TypographyUI>

              <CommonStyles.TypographyUI className="whitespace-pre-line">
                {`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim book. It has survived not only five centuries, but also the leap into electronic...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specim book. It has survived not only five centuries, but also the leap into electronic...Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            `}
              </CommonStyles.TypographyUI>
              <div className="my-4">
                <img className="w-full rounded-lg" src={CommonImages.DrafMedicalDetail} alt={CommonImages.DrafMedicalDetail} />
              </div>
            </div>
            {renderFooter()}
          </div>

          <div>
            <Comment />
          </div>

          <div>
            <CommonStyles.TypographyUI use="title" className="text-common-maintext font-semibold" level={3}>
              Bài viết khác
            </CommonStyles.TypographyUI>
            <div className="grid grid-cols-3 gap-8">{renderArticleOther()}</div>
          </div>
        </div>
      }
    />
  );
}
