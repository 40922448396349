import CommonImages from "@base/assets/images";
import TypographyUI from "../Typography";
import RatingUI from "../Rating";
import CommonIcons from "@base/assets/icons";
import ButtonUI from "../Button";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";
import { IDoctor } from "@base/modules/doctor/interface/doctor.interface";
import ImageUI from "../Image/Index";
import { getAvatarLink, limitString } from "@base/helpers/common";
import { responseUI } from "@base/themes/response";
import useToggle from "@base/hooks/useToggle";
import ModalConfirmationUI from "../ModalConfirmation";
import { useTranslation } from "react-i18next";
import TagUI from "../Tag";

interface IProps {
  doctor?: IDoctor;
}
export default function CardDoctor(props: IProps) {
  //! state
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { doctor } = props;
  const { open, shouldRender, toggle } = useToggle();

  //! function
  const handleDetailDoctor = () => {
    navigate(`${baseRoutes.doctor}/${doctor?.doctor_code}`);
  };
  const handleBookingDoctor = () => {
    let typeParams: null | string = null;
    if (location.pathname === baseRoutes.bookingOnline) {
      typeParams = "online";
      navigateToBookingPage(typeParams);
      return;
    }

    if (doctor?.offline_dr && doctor?.online_dr) {
      toggle();
      return;
    }
    if (doctor?.offline_dr) {
      typeParams = "offline";
    }
    if (doctor?.online_dr) {
      typeParams = "online";
    }

    navigateToBookingPage(typeParams);
  };

  const navigateToBookingPage = (typeParams: null | string) => {
    navigate({
      pathname: baseRoutes.bookingAppointment,
      search: createSearchParams({
        doctor_code: String(doctor?.doctor_code),
        site_code: String(doctor?.site_code),
        specialization_code: String(doctor?.specialization?.specialization_code),
        type: String(typeParams),
      }).toString(),
    });
  };

  //! render
  return (
    <div className="flex flex-col gap-4 p-6 rounded-2xl border justify-between bg-common-white">
      <div className="flex justify-center items-center ">
        <img
          className="rounded-full aspect-square w-[200px] lg:w-[250px]"
          src={getAvatarLink(doctor?.avatar ?? [])}
          alt={getAvatarLink(doctor?.avatar ?? [])}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = CommonImages.DefaultDoctorAvatar;
          }}
        />
      </div>

      <div className="flex justify-between">
        <TypographyUI
          className="text-common-maintext text-sm lg:text-base font-medium  line-clamp-1"
          title={`${doctor?.doctor_name} - ${doctor?.specialization?.specialization_name}`}
        >
          {doctor?.doctor_name}
        </TypographyUI>
        <RatingUI rating={doctor?.rating_point ?? 0} maxRating={5} />
      </div>
      <div className="flex gap-2">
        {doctor?.online_dr && (
          <TagUI className="bg-common-lineCard border-common-divider m-0 !rounded-[4px] !text-common-maintext" tagName="Tư vấn online" />
        )}
        {doctor?.offline_dr && (
          <TagUI className="bg-common-lineCard border-common-divider m-0 !rounded-[4px] !text-common-maintext" tagName="Tại cơ sở y tế" />
        )}
      </div>

      <div className="flex flex-col gap-3">
        <div className="flex gap-2">
          <div>
            <CommonIcons.IconGraduate />
          </div>
          <TypographyUI className="text-common-maintext text-xs lg:text-sm grow  line-clamp-1" title={String(doctor?.title?.title_name)}>
            {doctor?.title?.title_name}
          </TypographyUI>
        </div>
        <div className="flex gap-2 ">
          <div>
            <CommonIcons.PlusHome />
          </div>
          <TypographyUI className="text-common-maintext text-sm grow line-clamp-1" title={String(doctor?.specialization?.description)}>
            {doctor?.specialization?.description}
          </TypographyUI>
        </div>
        <div className="flex gap-2 line-clamp-1">
          <div>
            <CommonIcons.IconHospital />
          </div>
          <TypographyUI className="text-common-maintext text-xs lg:text-sm grow line-clamp-1" title={String(doctor?.site_name)}>
            {doctor?.site_name}
          </TypographyUI>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row  justify-between items-center gap-4">
        <ButtonUI onClick={handleBookingDoctor} className={`${responseUI.button.default} lg:text-base font-medium grow w-full`}>
          {t("home.bookingNow")}
        </ButtonUI>
        <ButtonUI
          onClick={handleDetailDoctor}
          className={`${responseUI.button.default} w-full lg:text-base font-medium grow bg-common-orange50 text-common-orange hover:!bg-common-orange100 hover:!text-common-orange`}
        >
          {t("home.ViewDetail")}
        </ButtonUI>
      </div>

      {shouldRender && (
        <ModalConfirmationUI
          type="normal"
          title="Chọn hình thức đặt lịch"
          open={open}
          toggle={toggle}
          hiddenAction
          content={<MethodBooking doctor={doctor} />}
        />
      )}
    </div>
  );
}

const MethodBooking = ({ doctor }: { doctor?: IDoctor }) => {
  const navigate = useNavigate();
  const handleBooking = (type: "online" | "offline") => {
    navigate({
      pathname: baseRoutes.bookingAppointment,
      search: createSearchParams({
        doctor_code: String(doctor?.doctor_code),
        site_code: String(doctor?.site_code),
        specialization_code: String(doctor?.specialization?.specialization_code),
        type: String(type),
      }).toString(),
    });
  };
  return (
    <div>
      <div className="flex flex-col gap-3">
        <ButtonUI
          icon={<CommonIcons.RightOutlined />}
          iconPosition="end"
          type="default"
          className={`${responseUI.button.default} font-medium bg-common-orange100 !py-8  min-w-64 justify-between`}
          onClick={() => {
            handleBooking("online");
          }}
        >
          <div className="flex gap-2 items-center">
            <CommonIcons.IconCameraBox />
            <TypographyUI className="text-base font-medium">Tư vấn online</TypographyUI>
          </div>
        </ButtonUI>

        <ButtonUI
          icon={<CommonIcons.RightOutlined />}
          iconPosition="end"
          type="default"
          className={`${responseUI.button.default} font-medium !py-8  bg-common-blue100 min-w-64 justify-between`}
          onClick={() => {
            handleBooking("offline");
          }}
        >
          <div className="flex gap-2 items-center ">
            <div className="">
              <CommonIcons.IconFacilityBox />
            </div>
            <TypographyUI className="text-base font-medium">Khám tại cơ sở y tế</TypographyUI>
          </div>
        </ButtonUI>
      </div>
    </div>
  );
};
