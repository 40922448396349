import CommonIcons from "@base/assets/icons";
import CommonImages from "@base/assets/images";
import themeColor, { initialPage } from "@base/themes/colorTheme";
import CommonStyles from "@components/CommonStyles";
import TagsKey from "@components/MedicalInfo/TagsKey";
import Outstanding from "@components/MedicalInfo/outstanding";
import React from "react";
import { NavLink } from "react-router-dom";

interface IProps {
  Header: React.ReactNode;
  Content: React.ReactNode;
}
export default function MedicalManualLayout(props: IProps) {
  //! state
  const { Header, Content } = props;
  const categories = [
    {
      title: "Tất cả",
      href: "/medicalInfo",
    },
    {
      title: "Thông tin y tế",
      href: "/",
    },
    {
      title: "Đánh giá cơ sở y tế",
      href: "/",
    },
    {
      title: "Sự kiện y tế",
      href: "/",
    },
  ];
  //! function

  const renderCategory = () => {
    return categories.map((item, index) => {
      return (
        <NavLink className={`text-common-subtext text-lg font-medium`} to={item.href} key={index}>
          {item.title}
        </NavLink>
      );
    });
  };
  //! render
  return (
    <div className="pb-24">
      <div className={`bg-common-white h-72 pb-8 relative ${initialPage.paddingX}`}>
        {Header}
        <div className="absolute right-0 bottom-0">
          <CommonIcons.IconHeart width={150} height={150} />
        </div>
      </div>

      <div className={`grid grid-cols-3 gap-8 mt-6 ${initialPage.paddingX}`}>
        {/* info */}
        <div className="col-span-2">{Content}</div>

        {/* search */}
        <div className="flex flex-col gap-8 p-4 rounded-lg bg-common-white h-fit">
          <CommonStyles.TypographyUI use="title" className="font-semibold text-common-maintext" level={3}>
            Tìm kiếm
          </CommonStyles.TypographyUI>
          <CommonStyles.InputUI
            className="!w-full h-12"
            variant="filled"
            prefix={<CommonIcons.SearchOutlined style={{ fontSize: 16, color: themeColor.orange }} />}
            placeholder="Tìm kiếm ..."
          />
          <div className="flex flex-col gap-4 my-8">
            <CommonStyles.TypographyUI use="title" level={3} className="font-semibold">
              Danh mục
            </CommonStyles.TypographyUI>
            {renderCategory()}
          </div>

          <div>
            <CommonStyles.TypographyUI use="title" level={3} className="font-semibold !mb-6">
              Bài viết nổi bật
            </CommonStyles.TypographyUI>

            <Outstanding />
          </div>

          <div>
            <CommonStyles.TypographyUI use="title" level={3} className="font-semibold !mb-6">
              Từ khóa phổ biến
            </CommonStyles.TypographyUI>
            <TagsKey />
          </div>

          {/* order */}
          <div className="border-common-orange border">
            <div
              className="m-3 px-3 py-7 flex flex-col items-center text-center gap-4"
              style={{
                backgroundImage: `url(${CommonImages.bgMedicalDownload})`,
                minHeight: 200,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <img className="w-24" src={CommonImages.logoMedicalDownload} alt={CommonImages.logoMedicalDownload} />
              <CommonStyles.TypographyUI use="paragraph" className="text-common-maintext">
                Đặt lịch khám - chữa bệnh tại 365 Medihome giúp chủ động thời gian, hạn chế tiếp xúc đông người.
              </CommonStyles.TypographyUI>
              <div>
                <CommonStyles.ButtonUI className="h-10 font-medium">Đặt lịch ngay</CommonStyles.ButtonUI>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
