import { IAuth } from "@base/modules/auth/auth.interface";
import { ResponseCommon } from "@base/modules/common/interface/Common.interface";
import { ResponseHomeModule } from "@base/modules/homeModules/interfaces/IHomeModule.interface";
import { IUserInfo } from "@base/modules/user/account/account.interface";
import { NotificationType } from "@base/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AlertMessage {
  type?: NotificationType;
  message?: React.ReactNode | React.ReactNode[];
  description?: React.ReactNode | React.ReactNode[];
}

interface TAppCommonState {
  auth?: IAuth;
  userInfo?: IUserInfo;
  alertMessage: AlertMessage;
  dataCommon?: ResponseHomeModule;
}

const initialState: TAppCommonState = {
  auth: undefined,
  userInfo: undefined,
  alertMessage: {},
  dataCommon: undefined,
};

export const appCommonSlice = createSlice({
  name: "app-common-state",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<IAuth | undefined>) => {
      state.auth = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<IUserInfo | undefined>) => {
      state.userInfo = action.payload;
    },
    setAlertMessage: (state, action: PayloadAction<AlertMessage>) => {
      state.alertMessage = action.payload;
    },
    setDataCommon: (state, action: PayloadAction<ResponseHomeModule>) => {
      state.dataCommon = action.payload;
    },
  },
});

export const { setAuth, setUserInfo, setAlertMessage, setDataCommon } = appCommonSlice.actions;

export default appCommonSlice.reducer;
